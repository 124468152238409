import React, { useMemo, useEffect, useCallback, useState, useRef, Fragment } from "react";
import { Layout, Menu, Dropdown, Radio, Input, Row, Modal, Button } from "antd";
import bookRenderer from "../../BookRenderer";
import * as style from "./style.less";
import { Definition } from "../../..";
import { useI18n, useI18nObjectHook } from "../../../../../../i18n";
import { useIsContentAdministrator, useIsEditor } from "../../../../../../identity";
import { SplitContent } from "../../EditingRouter/components/SplitContent";
import { getMenuItem, MenuItem } from "../../../../styles/antDesignTheme";
import { ExtendedDefinitionKind } from "../../../../../../../__generated__/globalTypes";
import {
  SubscribeToExtendedPage_extendedPage,
  SubscribeToExtendedPage_extendedPage_definition,
} from "../../../api/__generated__/SubscribeToExtendedPage";
import { usePageContext } from "../../EditingRouter/components/PageContentLoader";
import { SelectedTextPosition } from "../Formatting/components/FormattingComp/FormattingComp";
import { SubToPages_pages } from "../../../api/SubToPages";
import { useTextPosition } from "../../../hooks/useTextPosition";

const RadioButton = Radio.Button;

export type CreateDefinition = (
  start: number,
  size: number,
  definitionKind: ExtendedDefinitionKind,
  definition: string,
  definitionImageName?: string,
) => void;

export type UpdateDefinition = (
  definition: SubscribeToExtendedPage_extendedPage_definition,
  definitionKind: ExtendedDefinitionKind,
  description: string,
  imageName?: string,
) => void;

const RenderBook = bookRenderer();

type menuTranslation = {
  associates: string;
  modifies: string;
  add: string;
  dissociate: string;
};

type ContentProps = {
  setModalMode: (arg: "create" | "update") => void;
  projectId: string;
  setSelectedTextPosition: (arg: SelectedTextPosition) => void;
  deleteDefinition: (insertOrder: number) => void;
  updateSelectedDefinition: (definition: Definition) => void;
  translation: menuTranslation;
  setPageMetadata: (arg: SubscribeToExtendedPage_extendedPage) => void;
};

export const buildDefinitionMenu = (
  selectedDefinition: Definition | null,
  hasMultipleDefinitions: boolean,
  deleteDefinition: (insertOrder: number) => void,
  updateSelectedDefinition: (definition: Definition) => void,
  translation: menuTranslation,
  setModalMode: (arg: "create" | "update") => void,
) => {
  const items: MenuItem[] = [];

  if (selectedDefinition) {
    items.push(
      getMenuItem({
        key: "modifies",
        label: translation.modifies,
        onClick: () => {
          updateSelectedDefinition(selectedDefinition);
          setModalMode("update");
        },
      }),
    );
    items.push(
      getMenuItem({
        key: "dissociate",
        label: translation.dissociate,
        onClick: () => {
          deleteDefinition(selectedDefinition.insertOrder);
        },
        danger: true,
      }),
    );
  }
  if (!selectedDefinition) {
    items.push(
      getMenuItem({
        key: "add",
        label: translation.add,
        onClick: () => {
          setModalMode("create");
        },
        disabled: hasMultipleDefinitions,
      }),
    );
  }

  return <Menu items={items} />;
};

// eslint-disable-next-line react/display-name
export const DefinitionsLoader = React.memo((props: ContentProps) => {
  return <Content {...props} />;
});

// eslint-disable-next-line react/display-name
export const Content = React.memo(
  ({
    setPageMetadata,
    setSelectedTextPosition,
    deleteDefinition,
    updateSelectedDefinition,
    translation,
    setModalMode,
    projectId,
  }: ContentProps) => {
    // @ts-ignore
    const { extendedPage: pageMetadata }: { extendedPage: SubscribeToExtendedPage_extendedPage } =
      usePageContext();

    const isContentAdmin = useIsContentAdministrator();
    const isEditor = useIsEditor();
    const contentEditableRef = useRef<HTMLDivElement>(null);
    const [definitionKindFilter, setDefinitionKindFilter] = useState<
      ExtendedDefinitionKind | "ALL"
    >("ALL");
    const [selectedDefinition, setSelectedDefinition] = useState<null | Definition>(null);
    const [selectionHasMultipleDefinitions, setSelectionHasMultipleDefinitions] = useState(false);
    const { selectedTextPosition, saveSelection, reset: resetSelection } = useTextPosition();

    const [t] = useI18n();
    const i18nKey = "project.existing.editingMode.definition.content";

    // Pass pageMetadata to parent
    useEffect(() => {
      if (pageMetadata) {
        setPageMetadata(pageMetadata);
      }
    }, [pageMetadata, setPageMetadata]);

    // Pass selectedTextPosition to parent
    useEffect(() => {
      setSelectedTextPosition(selectedTextPosition);
    }, [selectedTextPosition, setSelectedTextPosition]);

    useEffect(() => {
      const noop = (e: Event) => {
        e.preventDefault();
        return false;
      };
      const ref = contentEditableRef.current;
      ref?.addEventListener("cut", noop, false);
      ref?.addEventListener("paste", noop, false);
      ref?.addEventListener("keydown", noop, false);
      ref?.addEventListener("dragenter", noop, false);
      ref?.addEventListener("dragleave", noop, false);
      ref?.addEventListener("dragover", noop, false);
      ref?.addEventListener("drop", noop, false);
      return () => {
        ref?.removeEventListener("cut", noop);
        ref?.removeEventListener("paste", noop);
        ref?.removeEventListener("keydown", noop);
        ref?.removeEventListener("dragenter", noop);
        ref?.removeEventListener("dragleave", noop);
        ref?.removeEventListener("dragover", noop);
        ref?.removeEventListener("drop", noop);
      };
    }, [contentEditableRef]);

    const renderDropdownContent = useCallback(
      () =>
        buildDefinitionMenu(
          selectedDefinition,
          selectionHasMultipleDefinitions,
          deleteDefinition,
          updateSelectedDefinition,
          translation,
          setModalMode,
        ),
      [
        selectedDefinition,
        selectionHasMultipleDefinitions,
        deleteDefinition,
        updateSelectedDefinition,
        translation,
        setModalMode,
      ],
    );

    // Save selection on mouse up and reset selection on mouse down
    const { onMouseUp, onMouseDown } = useMemo(() => {
      return {
        onMouseUp: saveSelection,
        onMouseDown: (e: React.MouseEvent) => {
          e.button === 0 && resetSelection();
        },
      };
    }, [saveSelection, resetSelection]);

    // Update selectedDefinition when selectedTextPosition changes
    useEffect(() => {
      if (selectedTextPosition.size > 0) {
        // Use selectedTextPosition to find the selected definitions
        const selectedDefinitions = pageMetadata.definition.filter(
          (definition: SubscribeToExtendedPage_extendedPage_definition) =>
            definition.anchors[0].utf16Start >= selectedTextPosition.start &&
            definition.anchors[0].utf16Start + definition.anchors[0].utf16Size <=
              selectedTextPosition.start + selectedTextPosition.size,
        );

        const multipleDefinitions = selectedDefinitions.length > 1;
        setSelectionHasMultipleDefinitions(multipleDefinitions);

        if (multipleDefinitions) {
          return setSelectedDefinition(null);
        }

        if (selectedDefinitions.length === 1) {
          return setSelectedDefinition({
            id: selectedDefinitions[0].data.id,
            definition: selectedDefinitions[0].data.description,
            kind: selectedDefinitions[0].data.kind,
            imageName: selectedDefinitions[0].data.imageName,
            insertOrder: selectedDefinitions[0].anchors[0].insertOrder,
          });
        }
      }
      setSelectionHasMultipleDefinitions(false);
      setSelectedDefinition(null);
    }, [pageMetadata, selectedTextPosition]);

    return (
      <Fragment>
        <Dropdown
          trigger={["contextMenu"]}
          dropdownRender={renderDropdownContent}
          disabled={!(isContentAdmin || isEditor)}>
          <div
            className={definitionKindFilter.toLowerCase()}
            ref={contentEditableRef}
            contentEditable="true"
            suppressContentEditableWarning
            onMouseUp={onMouseUp}
            onMouseDown={onMouseDown}>
            <RenderBook projectId={projectId}>{pageMetadata}</RenderBook>
          </div>
        </Dropdown>
        <div
          style={{
            position: "absolute",
            top: "20%",
            right: 0,
            padding: 0,
            width: "15vw",
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "1em",
              position: "relative",
              alignItems: "end",
            }}>
            <div
              onClick={() => setDefinitionKindFilter("ALL")}
              className={style.filter.concat(
                " ",
                (definitionKindFilter === "ALL" && style.selectedFilter) || "",
              )}>
              {t(`${i18nKey}.all`)}
            </div>
            <div
              onClick={() => setDefinitionKindFilter(ExtendedDefinitionKind.EXPLICIT)}
              className={style.filter.concat(
                " ",
                (definitionKindFilter === ExtendedDefinitionKind.EXPLICIT &&
                  style.selectedFilter) ||
                  "",
              )}>
              {t(`${i18nKey}.explicit`)}
            </div>
            <div
              onClick={() => setDefinitionKindFilter(ExtendedDefinitionKind.IMPLICIT)}
              className={style.filter.concat(
                " ",
                (definitionKindFilter === ExtendedDefinitionKind.IMPLICIT &&
                  style.selectedFilter) ||
                  "",
              )}>
              {t(`${i18nKey}.implicit`)}
            </div>
            <div
              onClick={() => setDefinitionKindFilter(ExtendedDefinitionKind.NDBP)}
              className={style.filter.concat(
                " ",
                (definitionKindFilter === ExtendedDefinitionKind.NDBP && style.selectedFilter) ||
                  "",
              )}>
              {t(`${i18nKey}.foot_note`)}
            </div>
          </div>
        </div>
      </Fragment>
    );
  },
);

type translationModalProps = {
  message: {
    assign: string;
    update: string;
    create: string;
  };
  radioButton: {
    explicit: string;
    implicit: string;
    foot_note: string;
    image: string;
  };
  textArea: {
    placeholder: string;
  };
  imageName: {
    placeholder: string;
  };
};

// eslint-disable-next-line react/display-name
export const DefinitionModal = React.memo(function ({
  onChange,
  onValidate,
  onCancel,
  selection,
  loadedDefinition,
  loadedDefinitionKind,
  loadedDefinitionImageName,
  mode,
  translation,
}: {
  mode: "create" | "update";
  onChange: (
    definition: string,
    definitionKind: ExtendedDefinitionKind,
    imageName?: string,
  ) => void;
  onValidate: () => void;
  onCancel: () => void;
  selection: string;
  loadedDefinition?: string;
  loadedDefinitionImageName?: string;
  loadedDefinitionKind?: ExtendedDefinitionKind;
  translation: translationModalProps;
}) {
  const [definition, setDefinition] = useState(loadedDefinition || "");
  const [imageName, setImageName] = useState(loadedDefinitionImageName || "");
  const [definitionKind, setDefinitionKind] = useState<ExtendedDefinitionKind>(
    loadedDefinitionKind || DEFAULT_DEFINITION_KIND,
  );

  useEffect(
    () => onChange(definition, definitionKind, imageName),
    [definition, definitionKind, imageName, onChange],
  );

  const handleDefinition = useCallback((e) => setDefinition(e.target.value), []);
  const handleImageName = useCallback((e) => setImageName(e.target.value), []);

  const title = selection;

  const message = useMemo(() => {
    // if (mode === "assign") {
    //   return translation.message.assign;
    // }
    if (mode === "update") {
      return translation.message.update;
    }
    return translation.message.create;
  }, [mode, translation]);

  const labelStyle: any = {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flex: 1,
    fontSize: "1em",
    fontWeight: "normal",
  };

  return (
    <Modal
      centered={true}
      width={"56.66em"}
      title={title}
      className={style.modal}
      open={true}
      onCancel={onCancel}
      footer={null}
      getContainer={() => document.body}>
      <Radio.Group
        // disabled={mode === "assign"}
        style={{
          display: "flex",
          fontSize: "1.25em",
        }}
        defaultValue={definitionKind}
        onChange={(e) => setDefinitionKind(e.target.value)}>
        <RadioButton style={labelStyle} value={ExtendedDefinitionKind.EXPLICIT}>
          {translation.radioButton.explicit}
        </RadioButton>
        <RadioButton style={labelStyle} value={ExtendedDefinitionKind.IMPLICIT}>
          {translation.radioButton.implicit}
        </RadioButton>
        <RadioButton style={labelStyle} value={ExtendedDefinitionKind.NDBP}>
          {translation.radioButton.foot_note}
        </RadioButton>
      </Radio.Group>
      <Input.TextArea
        // disabled={mode === "assign"}
        placeholder={translation.textArea.placeholder}
        defaultValue={definition}
        onChange={handleDefinition}
      />
      <Input
        // disabled={mode === "assign"}
        placeholder={translation.imageName.placeholder}
        defaultValue={imageName}
        onChange={handleImageName}
      />
      <Row justify="center" className={style.definitionActions}>
        <Button
          shape="round"
          onClick={onValidate}
          style={{
            color: "#61C374",
            borderColor: "#61C374",
          }}
          className={style.action}>
          {message}
        </Button>
      </Row>
    </Modal>
  );
});

type DefinitionProps = {
  pages: SubToPages_pages[];
  projectId: string;
  createDefinition: CreateDefinition;
  updateDefinition: UpdateDefinition;
  deleteDefinition: (insertOrder: number) => void;
};

const DEFAULT_DEFINITION_KIND = ExtendedDefinitionKind.EXPLICIT;

export default React.memo(function Definition({
  pages,
  projectId,
  createDefinition: remoteCreateDefinition,
  updateDefinition,
  deleteDefinition,
}: DefinitionProps) {
  const [modalMode, setModalMode] = useState<null | "create" | "update">(null);

  const [definition, setDefinition] = useState<string>("");
  const [definitionImageName, setDefinitionImageName] = useState<string>("");
  const [definitionKind, setDefinitionKind] =
    useState<ExtendedDefinitionKind>(DEFAULT_DEFINITION_KIND);
  const [selectedDefinition, setSelectedDefinition] = useState<Definition | null>(null);
  const [selectedTextPosition, setSelectedTextPosition] = useState<SelectedTextPosition>({
    start: 0,
    size: 0,
  });
  const [pageMetadata, setPageMetadata] = useState<SubscribeToExtendedPage_extendedPage | null>(
    null,
  );

  const onChange = useCallback(
    (definition: string, definitionKind: ExtendedDefinitionKind, imageName?: string) => {
      setDefinition(definition);
      setDefinitionImageName(imageName || "");
      setDefinitionKind(definitionKind);
    },
    [],
  );

  const reset = useCallback(() => {
    setDefinition("");
    setSelectedDefinition(null);
    setDefinitionKind(DEFAULT_DEFINITION_KIND);
    setModalMode(null);
  }, []);

  const createDefinition = useCallback(() => {
    if (selectedTextPosition.size > 0) {
      remoteCreateDefinition(
        selectedTextPosition.start,
        selectedTextPosition.size,
        definitionKind,
        definition,
        definitionImageName,
      );
    }
    reset();
  }, [
    remoteCreateDefinition,
    selectedTextPosition,
    definitionKind,
    definition,
    reset,
    definitionImageName,
  ]);

  const applyUpdateDefinition = useCallback(() => {
    if (!selectedDefinition) return;
    const foundDefinition = pageMetadata?.definition.find(
      (d) => d.data.id === selectedDefinition.id,
    );
    if (!foundDefinition) return;
    updateDefinition(foundDefinition, definitionKind, definition, definitionImageName);
    reset();
  }, [
    definitionImageName,
    selectedDefinition,
    updateDefinition,
    pageMetadata?.definition,
    definitionKind,
    definition,
    reset,
  ]);

  const objectTranslationDefinitionModal: translationModalProps = useI18nObjectHook(
    "project.existing.editingMode.definition.definitionModal",
  );

  const objectTranslationMenu: menuTranslation = useI18nObjectHook(
    "project.existing.editingMode.definition.buildDefinitionMenu",
  );

  const definitionModal = useMemo(() => {
    if (!pageMetadata) return null;
    const selection = pageMetadata.textContent.substring(
      selectedTextPosition.start - pageMetadata.page.anchors[0].utf16Start,
      selectedTextPosition.start -
        pageMetadata.page.anchors[0].utf16Start +
        selectedTextPosition.size,
    );

    if (selectedDefinition && modalMode === "update") {
      return (
        <DefinitionModal
          mode={"update"}
          selection={selection}
          onChange={onChange}
          onCancel={reset}
          onValidate={applyUpdateDefinition}
          loadedDefinition={selectedDefinition.definition || ""}
          loadedDefinitionImageName={selectedDefinition.imageName || ""}
          loadedDefinitionKind={selectedDefinition.kind}
          translation={objectTranslationDefinitionModal}
        />
      );
    }
    return (
      <DefinitionModal
        mode={"create"}
        selection={selection}
        onChange={onChange}
        onCancel={reset}
        onValidate={createDefinition}
        translation={objectTranslationDefinitionModal}
      />
    );
  }, [
    pageMetadata,
    selectedTextPosition,
    selectedDefinition,
    modalMode,
    onChange,
    reset,
    createDefinition,
    objectTranslationDefinitionModal,
    applyUpdateDefinition,
  ]);

  const props = useMemo(
    () => ({
      dissociateFromDefinition: deleteDefinition,
      setSelectedTextPosition,
      updateSelectedDefinition: setSelectedDefinition,
      translation: objectTranslationMenu,
      setModalMode,
      projectId,
      deleteDefinition,
      setPageMetadata,
    }),
    [deleteDefinition, objectTranslationMenu, projectId],
  );

  return (
    <Layout>
      <Layout.Content className={style.root}>
        <SplitContent component={DefinitionsLoader} props={{ ...props, projectId }}>
          {pages}
        </SplitContent>
      </Layout.Content>
      {!!modalMode && definitionModal}
    </Layout>
  );
});
