import React, { useCallback, useReducer, useMemo } from "react";
import { useSubscribeToPagesExtended, useSubToProject } from "../../api/api";
import { useNavigate } from "react-router-dom";
import { SubToPages_pages } from "../../api/SubToPages";
import { SubToProject_project } from "../../api/__generated__/SubToProject";

type UnfoldedPages = { [key: string]: number };
type UnfoldAction = { kind: "unfold"; page: string };

export function scrollToUrlPage() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const displayedPageNumber = urlParams.get("displayedPageNumber");
  const element = document.getElementById("page-" + displayedPageNumber);
  if (!element) return;

  // Ignore if page is already at least partly in the viewport
  const position = element.getBoundingClientRect();
  if (position.top < window.innerHeight / 2 && position.bottom >= 0) {
    return;
  }

  element.scrollIntoView();
}

export function useUpdateDisplayedPageNumber({ forceRedirect = false } = {}) {
  const navigate = useNavigate();

  return useCallback(
    (displayedPageNumber: string) => {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("displayedPageNumber", displayedPageNumber);

      if (window.location.search === "?" + queryParams.toString()) {
        return;
      }

      if (forceRedirect) {
        navigate(
          {
            pathname: window.location.pathname,
            search: "?" + queryParams.toString(),
          },
          { replace: true },
        );
      } else {
        window.history.replaceState({}, "", "?" + queryParams.toString());
      }
    },
    [forceRedirect, history],
  );
}

/**
 * Unfolded pages
 */

function reducer(state: UnfoldedPages, action: UnfoldAction) {
  if (action.kind === "unfold") {
    const newState = { ...state };
    newState[action.page] = (newState[action.page] || 0) + 1;
    return newState;
  }
  return state;
}

export function useUnfoldedPagesReducer() {
  const [unfoldedPages, sendUnfoldedPages] = useReducer(reducer, {});
  const res = useMemo(() => {
    return {
      state: unfoldedPages,
      send: {
        unfold: (page: string) => sendUnfoldedPages({ kind: "unfold", page }),
      },
    };
  }, [unfoldedPages, sendUnfoldedPages]);
  return res;
}

export type UnfoldedPageCtxType = {
  send: {
    unfold: (page: string) => void;
  };
  unfoldedPages: UnfoldedPages;
};

export const UnfoldedPageCtx = React.createContext<UnfoldedPageCtxType>({
  unfoldedPages: {},
  send: { unfold: () => {} },
});

/**
 * Misc.
 */

export function usePages(projectId: string, skip = false) {
  const { loading, data, error } = useSubscribeToPagesExtended({ variables: { projectId }, skip });

  const pages = useMemo(() => data?.pages || [], [data]);

  return { pages, loading, error };
}

type ProjectContextData = {
  project: SubToProject_project;
  pages: SubToPages_pages[];
};

const ProjectContext = React.createContext<ProjectContextData | null>(null);
export const ProjectProvider = ProjectContext.Provider;

export const useProjectContext = () => {
  const context = React.useContext(ProjectContext);
  if (!context) {
    throw new Error("useProjectContext must be used within a ProjectProvider");
  }
  return context;
};

export function useProject({
  projectId,
  withInfo = false,
  withPages = false,
  withAudioInfo = false,
}: {
  projectId: string;
  withInfo?: boolean;
  withPages?: boolean;
  withAudioInfo?: boolean;
}) {
  const { data: book, loading: bookLoading } = useSubToProject({
    id: projectId,
    withInfo,
    withAudioInfo,
  });

  const { pages, loading: pagesLoading } = usePages(projectId, !withPages);

  return {
    project: book?.project[0],
    pages,
    bookLoading,
    pagesLoading,
  };
}
