import { useLazyQuery } from "@apollo/client";
import React, { useState, MouseEvent, useEffect } from "react";
import { LRUCache as LRU } from "lru-cache";
import { getRecordingPresignedUrls } from "../../Queries/queries";
import { ClickableWrapper } from "../../../../components/clickableWrapper/ClickableWrapper";
import { audioRecording } from "../../Queries/__generated__/audioRecording";
import { useIsContentAdministrator } from "../../../../identity";
import { getRealStartAndEnd } from "../../pages/narrationBookPage/ProofListening/AudioPlayer/audioPlayerMachine/utils";
import { SubscribeToExtendedPage_extendedPage_rhese } from "../../../editor/Project/api/__generated__/SubscribeToExtendedPage";

type Props = {
  rhese: SubscribeToExtendedPage_extendedPage_rhese;
};

const audioCtx = new AudioContext();
const gainNode = audioCtx.createGain();
gainNode.connect(audioCtx.destination);

const audioCache = new LRU({ max: 50 });
const presignCache = new LRU<string, Promise<string | undefined>>({ max: 15 });

export const AudioPlayerByRhese = ({ rhese }: Props) => {
  const fileUuid = rhese.data.audioRecording?.fileUuid;
  const [getData] = useLazyQuery<audioRecording>(getRecordingPresignedUrls, {
    variables: { filename: rhese.data.audioRecording?.fileUuid },
    fetchPolicy: "cache-and-network",
  });

  const [presignedUrl, setPresignedUrl] = useState<Promise<string | undefined> | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!fileUuid) return;
    if (presignCache.get(fileUuid) !== undefined) {
      setPresignedUrl(presignCache.get(fileUuid));
      return;
    }

    const future = presignCache
      .set(
        fileUuid,
        getData().then(({ data }) => data?.audioRecording.presignedUrl.getUrl),
      )
      .get(fileUuid);
    setPresignedUrl(future);
  }, [fileUuid, getData]);

  const isContentAdmin = useIsContentAdministrator();

  const [isPlaying, setIsPlaying] = useState(false);

  const handleClick = async (e: MouseEvent<Element>) => {
    e.preventDefault();
    e.stopPropagation();
    const [start, end] = getRealStartAndEnd(rhese.data.audioInfo);
    if (typeof end !== "number" || typeof start !== "number") return;
    if (isPlaying) return;
    if (!fileUuid) return;
    const presignedGetUrl = await presignedUrl;
    if (!presignedGetUrl) return;

    gainNode.gain.setValueAtTime(0.3, audioCtx.currentTime);

    if (!audioCache.has(fileUuid)) {
      const resp = await fetch(presignedGetUrl, { mode: "cors" });
      const buffer = await audioCtx.decodeAudioData(await resp.arrayBuffer());
      audioCache.set(fileUuid, buffer);
    }

    const audioSource = audioCtx.createBufferSource();
    audioSource.connect(gainNode);
    audioSource.buffer = audioCache.get(fileUuid) as AudioBuffer;
    audioSource.start(audioCtx.currentTime, start, end - start);

    audioSource.onended = () => {
      setIsPlaying(false);
    };

    gainNode.gain.exponentialRampToValueAtTime(1, audioCtx.currentTime + 0.2);
  };

  return (
    <ClickableWrapper>
      <div>
        {isContentAdmin && (
          <span
            style={{
              color:
                rhese.data.audioInfo?.speechConfidence! > 0.8
                  ? "#61c374"
                  : rhese.data.audioInfo?.speechConfidence! < 0.5
                  ? "#e33636"
                  : "#e66525",
              fontSize: "18px",
              verticalAlign: "2px",
              marginRight: "15px",
            }}>
            Reco: {rhese.data.audioInfo?.speechConfidence?.toFixed(2)}
          </span>
        )}
        {isContentAdmin && (
          <span
            style={{
              color:
                rhese.data.audioInfo?.confidence! > 0.8
                  ? "#61c374"
                  : rhese.data.audioInfo?.confidence! < 0.5
                  ? "#e33636"
                  : "#e66525",
              fontSize: "18px",
              verticalAlign: "2px",
              marginRight: "5px",
            }}>
            Match: {rhese.data.audioInfo?.confidence?.toFixed(2)}
          </span>
        )}
        <svg
          onClick={handleClick}
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.9844 6H9.98438V11.4844C9.98438 12.1719 9.73438 12.7656 9.23438 13.2656C8.76562 13.7656 8.1875 14.0156 7.5 14.0156C6.8125 14.0156 6.21875 13.7656 5.71875 13.2656C5.25 12.7656 5.01562 12.1719 5.01562 11.4844C5.01562 10.7969 5.25 10.2188 5.71875 9.75C6.21875 9.25 6.8125 9 7.5 9C8.0625 9 8.5625 9.17188 9 9.51562V3.98438H12.9844V6ZM15.9844 0H2.01562C1.45312 0 0.96875 0.203125 0.5625 0.609375C0.1875 0.984375 0 1.45312 0 2.01562V15.9844C0 16.5469 0.1875 17.0312 0.5625 17.4375C0.96875 17.8125 1.45312 18 2.01562 18H15.9844C16.5469 18 17.0156 17.8125 17.3906 17.4375C17.7969 17.0312 18 16.5469 18 15.9844V2.01562C18 1.45312 17.7969 0.984375 17.3906 0.609375C17.0156 0.203125 16.5469 0 15.9844 0Z"
            fill="#6AA5EB"
          />
        </svg>
      </div>
    </ClickableWrapper>
  );
};
