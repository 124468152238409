import React, { useMemo } from "react";
import { Navigate, Route, Routes, useLocation, useMatch, useParams } from "react-router";
import { PaginatedOverview } from "../EditingModes/Overview/route";
import * as style from "../../style.less";
import { useSubToProject } from "../../api/api";
import { ProjectProvider } from "../Page";
import { ImportStatus, ProjectKind } from "../../../../../../__generated__/globalTypes";
import SliderFontSize from "../SliderFontSize/SliderFontSize";
import { useSliderFontSizeStore } from "../SliderFontSize/context";
import SubRouteNotImported from "./subroutes/SubRouteNotImported";
import { PaginatedRheseEdit } from "../EditingModes/Rhese";

import { PaginatedSyllable } from "./subroutes/SubRouteSyllable";
// import SubRouteNarration from "./subroutes/SubRouteNarration";
import PaginatedDefinition from "./subroutes/SubRouteDefinition";
import { subroutesPath } from "./subroutes/subroutesPath";
import { SubToPages_pages } from "../../api/SubToPages";
import GoToPage from "../GoToPage/GoToPage";
import SubRouteFormat from "./subroutes/SubRouteFormat";
import { PaginatedFormatting } from "../EditingModes/Formatting";

type Props = {
  projectId: string;
  pages: SubToPages_pages[];
  loading: boolean;
};

const EditingRouter = ({ projectId, loading, pages }: Props) => {
  const location = useLocation();
  const shouldDisplayFontResizer = !location.pathname.match(/narration/);

  const { loading: projectsLoading, data: projectsData } = useSubToProject({
    id: projectId,
    withInfo: false,
    withAudioInfo: false,
  });

  const project = useMemo(() => {
    if (projectsData?.project) {
      return projectsData?.project?.find((p) => p.id === projectId);
    }
  }, [projectId, projectsData?.project]);

  const { value: fontSize } = useSliderFontSizeStore();

  const matchesFormat = useMatch(subroutesPath.format);
  const matchesRhese = useMatch(subroutesPath.rhese);
  const matchesSyllable = useMatch(subroutesPath.syllable);
  const matchesDefinition = useMatch(subroutesPath.definition);
  const matchesOverview = useMatch(subroutesPath.overview(projectId));
  const lockPageStructure = project?.kind === ProjectKind.HTML_TEMPLATE;

  const paginatedComponent = useMemo(() => {
    if (!project) {
      return <SubRouteNotImported failed />;
    }

    if (matchesFormat) {
      return (
        <PaginatedFormatting
          projectId={projectId}
          lockPageStructure={lockPageStructure}
          allPages={pages}
          speakers={project?.speakers || []}
        />
      );
    }
    if (matchesRhese) {
      return <PaginatedRheseEdit projectId={projectId} allPages={pages} />;
    }
    if (matchesSyllable) {
      return <PaginatedSyllable project={project} allPages={pages} />;
    }
    if (matchesDefinition) {
      return <PaginatedDefinition projectId={projectId} allPages={pages} />;
    }
    if (matchesOverview) {
      return <PaginatedOverview projectId={projectId} allPages={pages} />;
    }
    return <Navigate to={subroutesPath.overview(projectId)} />;
  }, [
    matchesFormat,
    matchesRhese,
    matchesSyllable,
    matchesDefinition,
    matchesOverview,
    projectId,
    pages,
    project,
    lockPageStructure,
  ]);

  const matchesNarration = useMatch(subroutesPath.narration);

  if (project?.importStatus === ImportStatus.FAILED) {
    return <SubRouteNotImported failed />;
  }

  if (
    project?.importStatus === ImportStatus.WAITING_FOR_CONTENT ||
    project?.importStatus === ImportStatus.IMPORTING_CONTENT
  ) {
    return <SubRouteNotImported notImportedYet />;
  }

  if (!project || loading || projectsLoading) {
    return <SubRouteNotImported loading />;
  }

  return (
    <ProjectProvider
      value={{
        project,
        pages,
      }}>
      <div
        style={{ fontSize: `${fontSize}em` }}
        className={style.existingContainer}
        spellCheck={false}>
        {paginatedComponent}
      </div>
      {!matchesNarration ? <GoToPage totalPageNumber={pages?.[0]?.lastPageNumber} /> : null}
      {shouldDisplayFontResizer && <SliderFontSize style={style.fontSize} />}
    </ProjectProvider>
  );
};

export default React.memo(EditingRouter);
