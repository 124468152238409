import React, {
  Fragment,
  JSXElementConstructor,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { SplitProps } from "../types";
import { scrollToUrlPage } from "../../Page";
import PageBar from "./PageBar";
import Lazy from "./Lazy";
import { PageContentLoader } from "./PageContentLoader";
import {
  SubscribeToExtendedPage_extendedPage,
  SubscribeToExtendedPage_extendedPage_paragraph,
  SubscribeToExtendedPage_extendedPage_paragraph_data_image,
  SubscribeToExtendedPage_extendedPage_rhese,
} from "../../../api/__generated__/SubscribeToExtendedPage";
import { SubToPages_pages } from "../../../api/SubToPages";

interface RenderChildProps<
  T extends {
    projectId: string;
  },
> {
  page: SubToPages_pages;
  pageMetadata?: SubscribeToExtendedPage_extendedPage;
  i: number;
  isLast: boolean;
  component: JSXElementConstructor<
    T & {
      rheses?: SubscribeToExtendedPage_extendedPage_rhese[];
      paragraphs?: SubscribeToExtendedPage_extendedPage_paragraph[];
      images?: SubscribeToExtendedPage_extendedPage_paragraph_data_image[];
      page?: SubToPages_pages;
      pageMetadata?: SubscribeToExtendedPage_extendedPage;
      projectId: string;
      pageId: number;
    }
  >;
  addMutePages?: (pageId: string, amount: number) => void;
  mergePages?: (pageId: string) => void;
  props: T & {
    projectId: string;
    lockPageStructure?: boolean;
  };
}

const RenderChild = <T extends { projectId: string }>({
  page,
  props: renderChildProps,
  isLast,
  component: Component,
  addMutePages,
  mergePages,
}: RenderChildProps<T>) => {
  const [pageMetadata, setPageMetadata] = useState<SubscribeToExtendedPage_extendedPage>();
  const [pageSizeFactor, setPageSizeFactor] = useState(1);
  const [loadingMetadata, setLoadingMetadata] = useState(true);

  const hasMoreToLoad = useMemo(
    () =>
      (pageMetadata?.textContent.length || 0) < (pageMetadata?.page?.anchors?.[0].utf16Size || 0),
    [pageMetadata],
  );

  let optionalPageBar: React.ReactNode | undefined = undefined;
  if (mergePages && addMutePages && !renderChildProps.lockPageStructure) {
    optionalPageBar = (
      <PageBar
        displayedTotalPageNumber={page.lastPageNumber}
        pageNumber={page.pageNumber}
        mutePagesAfter={page.mutePagesAfter}
        mergePages={mergePages}
        addMutePages={addMutePages}
        isLast={isLast}
        pageId={page.id}
      />
    );
  }
  const loadMore = useCallback(() => {
    setPageSizeFactor((factor) => factor + 1);
  }, []);

  return (
    <Fragment key={page.id}>
      <Lazy
        pageId={page.id}
        displayedPageNumber={page.pageNumber}
        displayedTotalPageNumber={page.lastPageNumber}
        scrollBoxSelector="#project-root"
        loadMore={loadMore}
        hasMoreToLoad={hasMoreToLoad}
        loadingMore={loadingMetadata}>
        <PageContentLoader
          page={page}
          pageId={page.id}
          props={renderChildProps}
          projectId={renderChildProps.projectId}
          component={Component}
          setPageMetadata={(newPageMetadata: SubscribeToExtendedPage_extendedPage | undefined) =>
            newPageMetadata && setPageMetadata(newPageMetadata)
          }
          setLoadingMetadata={setLoadingMetadata}
          pageSizeFactor={pageSizeFactor}
        />
      </Lazy>
      {optionalPageBar}
    </Fragment>
  );
};

export const SplitContent: <
  T extends {
    rheses?: SubscribeToExtendedPage_extendedPage_rhese[];
    paragraphs?: SubscribeToExtendedPage_extendedPage_paragraph[];
    images?: SubscribeToExtendedPage_extendedPage_paragraph_data_image[];
    page?: SubToPages_pages;
    pageMetadata?: SubscribeToExtendedPage_extendedPage;
    pageId?: string;
    projectId: string;
    lockPageStructure?: boolean;
  },
>(
  props: SplitProps<T>,
) => React.ReactNode = React.memo(function SplitContent<
  T extends {
    rheses?: SubscribeToExtendedPage_extendedPage_rhese[];
    paragraphs?: SubscribeToExtendedPage_extendedPage_paragraph[];
    images?: SubscribeToExtendedPage_extendedPage_paragraph_data_image[];
    page?: SubToPages_pages;
    pageMetadata?: SubscribeToExtendedPage_extendedPage;
    pageId?: string;
    projectId: string;
    lockPageStructure?: boolean;
  },
>({ children = [], component: Component, props: splitProps, mergePages, addMutePages, pageMetadata }: SplitProps<T>) {
  useLayoutEffect(scrollToUrlPage, [children]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: "2em",
        padding: "2em 0",
        paddingBottom: "10em",
      }}>
      {children.map((page, i) => {
        return (
          <RenderChild
            key={page.id}
            pageMetadata={pageMetadata}
            page={page}
            i={i}
            isLast={i === children.length - 1}
            props={splitProps}
            component={Component}
            mergePages={mergePages}
            addMutePages={addMutePages}
          />
        );
      })}
    </div>
  );
}) as any;
