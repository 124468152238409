@import "../../../styles/colors.less";

#header {
  pointer-events: initial;
  position: relative;
  z-index: 999;
  height: 5.833em;
  display: grid;
  padding: 0;
  grid-template-columns: 2fr 5fr 2fr;
}

#header #tabs {
  grid-column: 2;
  width: 100%;
  height: 100%;
  & > * {
    height: 100%;
    width: 100%;
    margin: 0;
  }
  :global(.ant-tabs-tab) {
    svg {
      color: @dark-grey;
      fill: @dark-grey;
    }
    width: 6.66em;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    border-right: 1px solid fade(@dark-grey, 7%);
    &:first-child {
      border-left: 1px solid fade(@dark-grey, 7%);
    }
  }
  :global(.ant-tabs-tab):hover {
    background-color: fade(@grey, 10%);
    svg {
      path,
      circle,
      rect {
        fill: @orange;
      }
    }
  }
  :global(.ant-tabs-tab-active) {
    background-color: fade(@grey, 10%);
    svg {
      path,
      circle,
      rect {
        fill: @orange;
      }
    }
  }

  :global(.ant-tabs-tab-btn) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  :global(.ant-tabs-nav-list) {
    margin: 0 auto;
  }
}

.nav {
  padding: 0.2em 1.5em;
  padding-right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5em;
  overflow: hidden;

  button {
    line-height: 1;
    border: none;
    background: transparent;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.logo {
  font-size: 2em;
  line-height: 1;
  padding: 0;
}

.search {
  &:hover {
    cursor: pointer;

    svg {
      fill: @orange;
    }
  }

  svg {
    fill: @grey;
  }

  padding-right: 3em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 999;
  pointer-events: none;
  position: relative;
}
