import React from "react";
import Overview from ".";
import { SplitContent } from "../../EditingRouter/components/SplitContent";
import { SubToPages_pages } from "../../../api/SubToPages";
import { Route, useParams } from "react-router";

export const PaginatedOverview = (props: any) => {
  const { bookId } = useParams<{ bookId: string }>();
  return (
    <SplitContent
      component={Overview}
      props={{
        ...props,
        projectId: bookId,
      }}>
      {props.allPages}
    </SplitContent>
  );
};

// const RouteExport = (props: { allPages: SubToPages_pages[] }) => (
//   <Route path="/editor/books/edit/:bookId/overview" element={<CustomRoute {...props} />} />
// );

// export default RouteExport;
