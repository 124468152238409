import React from "react";
import { Navigate, Route, Routes, useNavigate, useParams } from "react-router";
import Centered from "../../../../../../components/Centered/Centered";
import { Button, Result } from "antd";
import { useI18nObjectHook } from "../../../../../../i18n";
import { Spinner } from "../../..";

type Props = {
  failed?: boolean;
  notImportedYet?: boolean;
  loading?: boolean;
};

export default function SubRouteNotImported({ failed, notImportedYet, loading }: Props) {
  const nvaigate = useNavigate();
  const t = useI18nObjectHook("project.importStatus");
  return (
    <>
      <Routes>
        <Route
          path="/editor/books/edit/:bookId"
          Component={() => {
            const { bookId } = useParams<{ bookId: string }>();
            return <Navigate to={`/editor/books/edit/${bookId}/overview`} />;
          }}
        />
      </Routes>
      {failed && (
        <Centered>
          <Result
            status="500"
            title={t.failed.title}
            subTitle={t.failed.message}
            extra={<Button onClick={() => nvaigate("/")}>{t.failed.action}</Button>}
          />
        </Centered>
      )}
      {notImportedYet && (
        <Centered>
          <Result
            icon={<Spinner />}
            title={t.inProgress.title}
            subTitle={t.inProgress.message}
            extra={<Button onClick={() => nvaigate("/")}>{t.inProgress.action}</Button>}
          />
        </Centered>
      )}
      {loading && (
        <>
          <Result
            style={{
              height: "100%",
              paddingTop: "25%",
            }}
            icon={<Spinner />}
            title={t.loading.title}
            subTitle={t.loading.message}
            extra={<Button onClick={() => nvaigate("/")}>{t.inProgress.action}</Button>}
          />
          <Spinner />
        </>
      )}
    </>
  );
}
