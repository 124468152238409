/* eslint-disable react/display-name */
import React, {
  createContext,
  Fragment,
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useLocation, useNavigate } from "react-router";
import _ from "lodash";
import classNames from "classnames";
import { Tooltip } from "antd";
import "./sharedBookStyle.less";

import { SearchContext } from "../Search";
import { LetterKind, convertPageContent } from "../../api/formatters";
import { useI18n } from "../../../../../i18n";
import { Rhese, Letter, checkIfPunctuation } from "../../../types";
import { SubToPageContent_page_rheses } from "../../api/SubToPageContent";
import { isFormatTab } from "../../../utils/common";
import {
  SubscribeToExtendedPage_extendedPage,
  SubscribeToExtendedPage_extendedPage_definition,
} from "../../api/__generated__/SubscribeToExtendedPage";

export const RheseOffset = createContext(0);

const i18nKey = "project.existing.bookRenderer.paraghapLabel";

const ParagrapLabel = {
  CENTRE: `${i18nKey}.center`,
  CITATION: `${i18nKey}.citation`,
  COURRIER: `${i18nKey}.mail`,
  DIALOGUE: `${i18nKey}.dialogue`,
  DIDASCALIE: `${i18nKey}.didascalie`,
  DROITE: `${i18nKey}.right`,
  EMAIL: `${i18nKey}.email`,
  ESPACE: `${i18nKey}.space`,
  GRAS: `${i18nKey}.bold`,
  INTRO: `${i18nKey}.intro`,
  NORMAL: `${i18nKey}.normal`,
  NOTE: `${i18nKey}.note`,
  RESUME: `${i18nKey}.résumé`,
  TEXTO: `${i18nKey}.text`,
  TITRE_1: `${i18nKey}.title1`,
  TITRE_2: `${i18nKey}.title2`,
  TITRE_3: `${i18nKey}.title3`,
  TITRE_4: `${i18nKey}.title4`,
  TITRE_5: `${i18nKey}.title5`,
  TITRE_6: `${i18nKey}.title6`,
  TITRE1: `${i18nKey}.title1`,
  TITRE2: `${i18nKey}.title2`,
  TITRE3: `${i18nKey}.title3`,
  TITRE4: `${i18nKey}.title4`,
  TITRE5: `${i18nKey}.title5`,
  TITRE6: `${i18nKey}.title6`,
  VERS: `${i18nKey}.vers`,
  LIST_ORDERED: `${i18nKey}.listOrdered`,
  LIST_UNORDERED: `${i18nKey}.listUnordered`,
  LIST_DEFINITION: `${i18nKey}.listDefinition`,
};

// Allow to know what color should be the syllable
let DIRTY_SYLLABLE_COUNTER = (function () {
  let counter = 0;
  let lastSyllableId = "";
  return (syllableId?: string) => {
    if (syllableId === "RESET") {
      lastSyllableId = "";
      return (counter = 0);
    } else if (syllableId === undefined || syllableId === null) {
      return counter;
    } else if (syllableId === lastSyllableId) {
      return counter;
    } else if (syllableId !== lastSyllableId) {
      lastSyllableId = syllableId;
      return (counter += 1);
    }
    return counter;
  };
})(); // Need to find a proper way to get a global counter

type LettersGroup = {
  isEven?: boolean;
  letters: Letter[];
  definitionOccurence?: {
    id: string;
  };
};

type DefinitionWithGroups = {
  definition: SubscribeToExtendedPage_extendedPage_definition;
  groups: LettersGroup[];
};

export const isDefinitionWithGroups = (val: any): val is DefinitionWithGroups =>
  val?.groups && val?.definition;

const convertRhese = (
  rhese: Rhese,
  definitions: SubscribeToExtendedPage_extendedPage_definition[],
) => {
  const definitionMode = window.location.pathname.includes("definition");

  const rheseDefinitions = definitionMode
    ? definitions.filter((definition) =>
        rhese.letters.some(
          (letter) =>
            letter.absoluteTextPosition >= definition.anchors[0].utf16Start &&
            letter.absoluteTextPosition <
              definition.anchors[0].utf16Start + definition.anchors[0].utf16Size,
        ),
      )
    : [];

  let lettersGroups = rhese.letters.map((letter) => {
    return {
      isEven: letter.syllableId ? DIRTY_SYLLABLE_COUNTER(letter.syllableId) % 2 === 0 : undefined,
      letters: [letter],
    };
  });

  const result: (LettersGroup[] | DefinitionWithGroups)[] = [];
  const orderedDefinitions: SubscribeToExtendedPage_extendedPage_definition[] = [
    ...rheseDefinitions,
  ];
  orderedDefinitions.sort((a, b) => a.anchors[0].utf16Start - b.anchors[0].utf16Start);

  let currentDefinition = orderedDefinitions.shift();
  let currentGroup: LettersGroup[] | DefinitionWithGroups = [];

  for (let lettersGroup of lettersGroups) {
    let letter = lettersGroup.letters[0];

    if (
      currentDefinition &&
      letter.absoluteTextPosition >= currentDefinition.anchors[0].utf16Start &&
      letter.absoluteTextPosition <
        currentDefinition.anchors[0].utf16Start + currentDefinition.anchors[0].utf16Size
    ) {
      if (
        isDefinitionWithGroups(currentGroup) &&
        currentGroup.definition.data.id === currentDefinition.data.id
      ) {
        currentGroup.groups.push({
          isEven: letter.syllableId
            ? DIRTY_SYLLABLE_COUNTER(letter.syllableId) % 2 === 0
            : undefined,
          letters: [letter],
          definitionOccurence: currentGroup.groups[0].definitionOccurence,
        });
      } else {
        result.push(currentGroup);
        currentGroup = {
          definition: currentDefinition,
          groups: [lettersGroup],
        };
      }
      if (
        letter.absoluteTextPosition ===
        currentDefinition.anchors[0].utf16Start + currentDefinition.anchors[0].utf16Size - 1
      ) {
        currentDefinition = orderedDefinitions.shift();
      }
    } else {
      if (isDefinitionWithGroups(currentGroup)) {
        result.push(currentGroup);
        currentGroup = [lettersGroup];
      } else {
        currentGroup.push(lettersGroup);
      }
    }
  }

  if (result[result.length - 1] !== currentGroup) {
    result.push(currentGroup);
  }

  return result.flat();
};

const RenderLetter = ({
  letter,
  i,
  evenOdd,
  isPunctuation,
  hasSyllable,
  enableCustomCssTooltip,
  highlightSpaces = false,
}: {
  letter: Letter;
  i: number;
  evenOdd: "" | "impair" | "pair";
  isPunctuation: boolean;
  hasSyllable: boolean;
  enableCustomCssTooltip: boolean;
  highlightSpaces?: boolean;
}) => {
  let phoneme = "";
  if (letter.phoneme && !letter.isMute) {
    phoneme = "phoneme " + letter.phoneme;
  }

  let customColoration = "";
  if (letter.customColoration) {
    customColoration = "customColoration " + "color" + letter.customColoration;
  }

  const tagProps = {
    "data-index": i,
    "data-absolute-position": letter.absoluteTextPosition,
    "data-id": letter.id,
    key: letter.id || i,
    className: classNames(
      isPunctuation
        ? [
            "ponctuation",
            "circleContainerTooltipClasses",
            letter.kind?.filter((k) => LetterKind.CITATION === k),
          ]
        : [
            "letter",
            "circleContainerTooltipClasses",
            ...(highlightSpaces && (letter.char === " " || letter.char === "\xa0")
              ? ["highlightedSpace"]
              : []),
            evenOdd,
            phoneme,
            customColoration,
            letter.kind,
            {
              muet: letter.isMute,
              insecable: letter.char === "\xa0",
              syllable: hasSyllable,
            },
          ],
    ),
  };

  let Tag;

  if (letter.kind?.includes(LetterKind.EXPOSANT)) {
    Tag = "sup" as keyof JSX.IntrinsicElements;
  } else if (letter.kind?.includes(LetterKind.INDICE)) {
    Tag = "sub" as keyof JSX.IntrinsicElements;
  } else {
    Tag = "span" as keyof JSX.IntrinsicElements;
  }

  if (!enableCustomCssTooltip) {
    return <Tag {...tagProps}>{letter.char}</Tag>;
  }

  return (
    <Tooltip title={letter.cssClasses}>
      <Tag {...tagProps}>
        {/* The <span/> below is needed to avoid a bad display when rheses on the same line both have and have not <Tooltip/>'s title */}
        <>
          {letter.char}
          {!!letter.cssClasses && <span className="circleTooltipClasses" />}
        </>
      </Tag>
    </Tooltip>
  );
};
const isEvenOdd = (bool?: boolean) => {
  let isEven: "" | "pair" | "impair" = "";
  if (bool === true) {
    isEven = "pair";
  }
  if (bool === false) {
    isEven = "impair";
  }
  return isEven;
};

const RenderText = ({
  rheseNumber,
  groupedLetters,
  enableCustomCssTooltip,
  highlightSpaces,
}: {
  rheseNumber: number;
  groupedLetters: (LettersGroup | DefinitionWithGroups)[];
  enableCustomCssTooltip: boolean;
  highlightSpaces: boolean;
}) => {
  let counter = 0;
  return (
    <Fragment>
      <span className="rheseNumber">{rheseNumber}</span>
      {groupedLetters.map((lettersGroup, i) => {
        if (isDefinitionWithGroups(lettersGroup)) {
          return (
            <Tooltip
              key={i}
              title={
                lettersGroup.definition.data.description || lettersGroup.definition.data.imageName
              }>
              <span className={`definition ${lettersGroup.definition.data.kind.toLowerCase()}`}>
                {lettersGroup.groups.reduce((acc: React.ReactNode[], val, currentIndex) => {
                  const res = val.letters.map((letter, j) => {
                    return (
                      <RenderLetter
                        key={`${letter.id}-${j}-${currentIndex}`}
                        enableCustomCssTooltip={enableCustomCssTooltip}
                        letter={letter}
                        i={counter++}
                        evenOdd={isEvenOdd(val.isEven)}
                        isPunctuation={!!checkIfPunctuation(letter.char)}
                        hasSyllable={letter.syllableId !== undefined && letter.syllableId !== null}
                      />
                    );
                  });
                  acc = acc.concat(res);
                  return acc;
                }, [])}
              </span>
            </Tooltip>
          );
        }
        return (
          <Fragment key={i}>
            {lettersGroup.letters.map((letter, lgIdx) => (
              <RenderLetter
                key={`${letter.id}-${lgIdx}`}
                letter={letter}
                i={counter++}
                evenOdd={isEvenOdd(lettersGroup.isEven)}
                isPunctuation={!!checkIfPunctuation(letter.char)}
                hasSyllable={letter.syllableId !== undefined && letter.syllableId !== null}
                enableCustomCssTooltip={enableCustomCssTooltip}
                highlightSpaces={highlightSpaces}
              />
            ))}
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export const RenderRhese = React.memo(
  ({
    children: rhese,
    activeStart,
    activeEnd,
    active,
    rheseProps,
    index,
    definitions,
    spaceIfNeeded,
    tooltipLength,
    isSearchResult,
    enableCustomCssTooltip,
    highlightSpaces,
  }: {
    definitions: SubscribeToExtendedPage_extendedPage_definition[];
    children: SubToPageContent_page_rheses;
    activeStart: boolean;
    activeEnd: boolean;
    active: boolean;
    rheseProps?: RheseOverrideProps;
    index: number;
    selectedSeparator?: string;
    spaceIfNeeded: React.ReactNode | null;
    tooltipLength?: boolean;
    isSearchResult: boolean;
    enableCustomCssTooltip: boolean;
    highlightSpaces: boolean;
  }) => {
    const isOdd = index === 0 || (index / 10) % 2 === 0;
    const onMouseUp = useCallback(() => {
      if (!rheseProps || !rheseProps.onMouseUp) return;
      rheseProps.onMouseUp(rhese);
    }, [rhese, rheseProps]);
    const onMouseDown = useCallback(() => {
      if (!rheseProps || !rheseProps.onMouseDown) return;
      rheseProps.onMouseDown(rhese);
    }, [rhese, rheseProps]);

    let warningLevel = "";
    const len = rhese.letters.length;
    if (len >= 31) warningLevel = "warningLight";
    if (len >= 36) warningLevel = "warning";
    if (len >= 40) warningLevel = "warningStrong";

    const props = {
      onMouseUp,
      onMouseDown,
      key: rhese.id,
      id: "r" + index,
      "data-id": rhese.id,
      className: `
            rhese
            ${isOdd ? "rhese1" : "rhese2"}
            ${activeStart ? "activeStart" : ""} 
            ${activeEnd ? "activeEnd" : ""}
            ${active ? "active" : ""}
            ${highlightSpaces ? "borderGreen" : ""}
            ${warningLevel}
          `,
    };

    const convertedRhese = useMemo(() => {
      return convertRhese(rhese as unknown as Rhese, definitions);
    }, [rhese, definitions]);

    const content = (
      <span className={`rheseWrapper ${isSearchResult ? "isSearchResult" : ""}`}>
        <span {...props}>
          <RenderText
            rheseNumber={index}
            groupedLetters={convertedRhese}
            enableCustomCssTooltip={enableCustomCssTooltip}
            highlightSpaces={highlightSpaces}
          />
        </span>
        {spaceIfNeeded}
      </span>
    );

    return (
      <Fragment>
        {tooltipLength ? (
          <Tooltip title={`${rhese.letters.length} charact.`} placement="right">
            {content}
          </Tooltip>
        ) : (
          content
        )}
        {rhese.lineBreakAfter && (
          <span>
            ↲<br className="lineBreak" />
          </span>
        )}
      </Fragment>
    );
  },
  (prevProps, nextProps) => {
    return Object.keys(prevProps).every((key) => {
      if (key === "definitions" && prevProps[key].length === nextProps[key].length) return true;
      if (
        key === "spaceIfNeeded" &&
        prevProps[key]?.props?.children === nextProps[key]?.props?.children
      )
        return true;
      //@ts-ignore
      return prevProps[key] === nextProps[key];
    });
  },
);

type RheseOverrideProps = {
  onMouseUp?: (rhese: SubToPageContent_page_rheses) => void;
  onMouseDown?: (rhese: SubToPageContent_page_rheses) => void;
};

type BookProps<T> = {
  paragraphWrapper?: {
    component: JSXElementConstructor<
      T & {
        children: ReactElement;
        paragraphId: string;
        isLastParagraph: boolean;
      }
    >;
    props: Omit<T, "children" | "paragraphId" | "isLastParagraph">;
  };
  rheseWrapper?: (
    rhese: SubToPageContent_page_rheses,
    renderedRhese: ReactElement,
  ) => ReactElement | ReactElement[];
  children: SubscribeToExtendedPage_extendedPage;
  startRhese?: Element;
  endRhese?: Element;
  selectedRhesesIds?: string[];
  rheseProps?: RheseOverrideProps;
  selectedSeparator?: string;
  previousElementCount?: number;
  tooltipLength?: boolean;
  enableParagraphTooltip?: boolean;
  projectId: string;
  rheseOffset?: number;
  highlightSpaces?: boolean;
};

const RenderRoot = React.memo(
  ({
    rhese,
    definitions,
    rheseCounter,
    activeStart,
    activeEnd,
    active,
    rheseProps,
    selectedSeparator,
    addSpaceAfter,
    tooltipLength,
    rheseWrapper,
    isSearchResult,
    highlightSpaces,
  }: {
    rhese: SubToPageContent_page_rheses;
    definitions: SubscribeToExtendedPage_extendedPage_definition[];
    rheseCounter: number;
    activeStart: boolean;
    activeEnd: boolean;
    active: boolean;
    rheseProps?: RheseOverrideProps;
    selectedSeparator?: string;
    addSpaceAfter: boolean;
    tooltipLength?: boolean;
    highlightSpaces: boolean;
    isSearchResult: boolean;
    rheseWrapper?: (
      rhese: SubToPageContent_page_rheses,
      renderedRhese: ReactElement,
    ) => ReactElement | ReactElement[];
  }) => {
    const spaceIfNeeded = addSpaceAfter ? <span className="space">&nbsp;</span> : null;

    const wrapper = rheseWrapper || ((_: any, renderedRhese: ReactElement) => renderedRhese);
    const location = useLocation();

    return (
      <Fragment>
        {wrapper(
          rhese,
          <RenderRhese
            isSearchResult={isSearchResult}
            definitions={definitions}
            index={rheseCounter}
            activeStart={activeStart}
            activeEnd={activeEnd}
            active={active}
            rheseProps={rheseProps}
            selectedSeparator={selectedSeparator}
            spaceIfNeeded={spaceIfNeeded}
            tooltipLength={tooltipLength}
            enableCustomCssTooltip={isFormatTab(location.pathname)}
            highlightSpaces={highlightSpaces}>
            {rhese}
          </RenderRhese>,
        )}
      </Fragment>
    );
  },
  (prevProps, nextProps) => {
    return Object.keys(prevProps).every((key) => {
      //@ts-ignore
      return prevProps[key] === nextProps[key];
    });
  },
);

type Paragraph = {
  type: "paragraph";
  id: string;
  tagName?: string;
  rheses: SubToPageContent_page_rheses[];
};

const isParagraph = (p: any): p is Paragraph => p.type === "paragraph";

export default function <T>() {
  return React.memo(function ({
    children: pageMetadata,
    startRhese,
    endRhese,
    selectedRhesesIds,
    selectedSeparator,
    rheseProps,
    tooltipLength,
    enableParagraphTooltip = false,
    rheseWrapper,
    rheseOffset = 0,
    paragraphWrapper,
    projectId,
    highlightSpaces,
  }: BookProps<T>) {
    // const rheseOffset = useContext(RheseOffset);
    let rheseCounter = rheseOffset;
    let paragraphCounter = 0;
    DIRTY_SYLLABLE_COUNTER("RESET");
    const [t] = useI18n();
    const { rheses: convertedRheses, paragraphs: convertedParagraphs } = useMemo(
      () => convertPageContent(projectId, pageMetadata),
      [pageMetadata, projectId],
    );

    const paragraphsMap = useMemo(
      () => _.groupBy(convertedRheses, "paragraphId"),
      [convertedRheses],
    );

    const contentWithParagraphs = useMemo(
      () =>
        convertedRheses.reduce((acc: (SubToPageContent_page_rheses | Paragraph)[], val) => {
          if (val.paragraphId === undefined) {
            acc.push(val);
          } else if (!acc.filter(isParagraph).find((p) => p.id === val.paragraphId)) {
            const p = convertedParagraphs.find((p) => p.id === val.paragraphId);

            acc.push({
              type: "paragraph",
              tagName: p?.tag || "",
              id: val.paragraphId,
              rheses: paragraphsMap[val.paragraphId],
            });
          }

          return acc;
        }, []),
      [paragraphsMap, convertedRheses, convertedParagraphs],
    );

    const { results: searchResults } = useContext(SearchContext);

    const renderer = {
      rhese: (rhese: SubToPageContent_page_rheses) => {
        const activeStart = rhese.id === startRhese?.id;
        const activeEnd = rhese.id === endRhese?.id;
        const active = !!(selectedRhesesIds || []).find((id) => rhese.id === id);
        const isSearchResult = !!searchResults.find((result) => result.rhese.data.id === rhese.id);

        return (
          <RenderRoot
            isSearchResult={isSearchResult}
            rheseWrapper={rheseWrapper}
            key={rhese.id}
            rheseCounter={(rheseCounter += 10)}
            rhese={rhese}
            definitions={pageMetadata.definition}
            activeStart={activeStart}
            activeEnd={activeEnd}
            active={active}
            addSpaceAfter={true}
            selectedSeparator={selectedSeparator}
            rheseProps={rheseProps}
            tooltipLength={tooltipLength}
            highlightSpaces={!!highlightSpaces}
          />
        );
      },
      paragraph: (p: Paragraph) => {
        const paragrapheKind = convertedParagraphs.find((d) => d.id === p.id)?.kind;

        paragraphCounter += 1;

        const labelKey = paragrapheKind || "NORMAL";

        const content = (
          <p
            id={`p${paragraphCounter}`}
            data-toto={`${p.id}`}
            className={`paragraph ${paragrapheKind}`}>
            {p.rheses.map((rhese) => (
              <Fragment key={rhese.id}>{renderer.rhese(rhese)}</Fragment>
            ))}
          </p>
        );

        return enableParagraphTooltip ? (
          <Tooltip
            title={
              <span>
                {t(ParagrapLabel[labelKey])}
                {p.tagName ? (
                  <>
                    <br />
                    {p.tagName}
                  </>
                ) : null}
              </span>
            }
            placement={"right"}>
            {content}
          </Tooltip>
        ) : (
          content
        );
      },
    };

    return (
      <>
        {contentWithParagraphs.map((content, i) => {
          if (isParagraph(content)) {
            const paragraph = renderer.paragraph(content);
            const Wrapper = paragraphWrapper?.component;
            const props = paragraphWrapper?.props;

            return (
              <Fragment key={i}>
                <div>
                  {Wrapper && props ? (
                    <Wrapper
                      {...(props as any)}
                      paragraphId={content.id}
                      isLastParagraph={i === contentWithParagraphs.length - 1}>
                      {paragraph}
                    </Wrapper>
                  ) : (
                    paragraph
                  )}
                </div>
              </Fragment>
            );
          }
        })}
      </>
    );
  });
}
