import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  SoundOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import moment from "moment";
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ExportStatus, ExportType } from "../../../__generated__/globalTypes";
import { SubscribeToExports_exports } from "../../app/editor/Project/api/__generated__/SubscribeToExports";
import { getExportDuration } from "../../app/editor/utils/common";
import { Spinner } from "../Spinner";
import * as style from "./style.less";

type Params = {
  exports: SubscribeToExports_exports[];
  download: (projectId: string, exportId: string) => void;
  cancel: (projectId: string, exportId: string) => void;
  deleteExportsProps: (exportIds: string[]) => void;
  deletingExportIds: string[];
};

interface DataType {
  key: React.Key;
  status: React.ReactNode | null;
  type: ExportType;
  projectName: string;
  author: string;
  createdAt: string;
  completedAt: string;
  duration: string;
  includeAudio: React.ReactNode | null;
  config: ReactElement<any, any>;
  action: React.ReactNode | null;
}

export const getStatusIcon = (status: ExportStatus) => {
  switch (status) {
    case ExportStatus.WAITING:
      return <ClockCircleOutlined className={style.iconWaiting} />;
    case ExportStatus.IN_PROGRESS:
      return <Spinner small notFlex />;
    case ExportStatus.DONE:
      return <CheckCircleOutlined className={style.iconDone} />;
    case ExportStatus.FAILED:
      return <CloseCircleOutlined className={style.iconFailed} />;
    default:
      return null;
  }
};

const getActionBtn = (
  exp: SubscribeToExports_exports,
  download: Params["download"],
  cancel: Params["cancel"],
  deletingExportIds: string[],
) => {
  const isDeleting = deletingExportIds.includes(exp.id);
  if (exp.exportStatus === ExportStatus.DONE) {
    return (
      <Button
        onClick={() => download(exp.projectId, exp.id)}
        loading={isDeleting}
        disabled={isDeleting}>
        {!isDeleting && <DownloadOutlined />}
      </Button>
    );
  } else if (
    exp.exportStatus === ExportStatus.WAITING ||
    exp.exportStatus === ExportStatus.IN_PROGRESS
  ) {
    return (
      <Button
        onClick={() => cancel(exp.projectId, exp.id)}
        loading={isDeleting}
        disabled={isDeleting}>
        {!isDeleting && <StopOutlined className={style.cancelIcon} />}
      </Button>
    );
  }

  return null;
};

export default function useTable({
  exports,
  cancel,
  download,
  deleteExportsProps,
  deletingExportIds,
}: Params) {
  const [t] = useTranslation();

  // Data used by the table
  const data: DataType[] = useMemo(
    () =>
      exports.map((exp) => ({
        key: exp.id,
        status: getStatusIcon(exp.exportStatus),
        type: t(`exportType_${exp.exportType}`),
        projectName: exp.projectName,
        author: exp.author,
        createdAt: moment(exp.createdAt).format("L HH:mm"),
        completedAt: exp.completedAt ? moment(exp.completedAt).format("L HH:mm") : "",
        duration: exp.completedAt ? getExportDuration(exp.createdAt, exp.completedAt) : "",
        includeAudio: exp.config.include_audio ? <SoundOutlined /> : null,
        config: (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p>
              {exp.config.coloration.custom
                ? t("project.existing.index.syllableComponent.custom")
                : ""}
            </p>
            <p>
              {exp.config.coloration.phoneme
                ? t("project.existing.index.syllableComponent.phonemes")
                : ""}
            </p>
            <p>
              {exp.config.coloration.syllable
                ? t("project.existing.index.syllableComponent.syllables")
                : ""}
            </p>
          </div>
        ),
        action: getActionBtn(exp, download, cancel, deletingExportIds),
      })),
    [cancel, download, exports, deletingExportIds, t],
  );

  // Rows selection logic
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const rowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onChange: setSelectedRowKeys,
    }),
    [selectedRowKeys],
  );
  const hasSelected = selectedRowKeys.length > 0;

  const deleteExports = useCallback(async () => {
    await deleteExportsProps(selectedRowKeys as string[]);
    setSelectedRowKeys([]);
  }, [deleteExportsProps, selectedRowKeys]);

  return {
    data,
    rowSelection,
    hasSelected,
    deleteExports,
  };
}
