import { CheckOutlined, CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, InputNumber, Row } from "antd";
import React, { useCallback, useState } from "react";
import { useI18n } from "../../../../../../i18n";
import style from "../../../style.less";
import MergePagesIcn from "../../../assets/merge.svg";
import MutePageIcn from "../../../assets/mutepage.svg";

type PageBarProps = {
  mergePages: (pageId: string) => void;
  addMutePages: (pageId: string, amount: number) => void;
  pageId: string;
  isLast: boolean;
  pageNumber: number;
  displayedTotalPageNumber: number;
  mutePagesAfter: number;
};

export default function PageBar({
  mergePages,
  addMutePages,
  pageId,
  isLast,
  mutePagesAfter,
  pageNumber,
  displayedTotalPageNumber,
}: PageBarProps) {
  const [isAddingMute, setIsAddingMute] = useState(false);
  const [mutePageNumber, setMutePageNumber] = useState(mutePagesAfter);
  const applyAddMutePages = useCallback(
    async (pageId: string, mutePageNumber: number) => {
      await addMutePages(pageId, mutePageNumber);
      setIsAddingMute(false);
    },
    [addMutePages],
  );

  let content;

  const [t] = useI18n();
  const i18nKey = "project.existing.index.pageBar";

  if (isAddingMute) {
    content = (
      <div className={style.addingMute}>
        <div>
          <InputNumber
            size="small"
            defaultValue={mutePageNumber}
            onChange={(e) => setMutePageNumber(Number(e))}
            onPressEnter={() => applyAddMutePages(pageId, mutePageNumber)}
          />
          <span>{t(`${i18nKey}.mutePage`)}</span>
        </div>
        <Row>
          <Button
            disabled={mutePageNumber <= 0}
            onClick={() => {
              if (mutePageNumber <= 0) return;
              applyAddMutePages(pageId, mutePageNumber);
            }}
            shape="circle">
            <CheckOutlined style={{ color: "#61C374" }} />
          </Button>
          <Button
            onClick={() => {
              setIsAddingMute(false);
            }}
            shape="circle">
            <CloseOutlined style={{ color: "#353A52" }} />
          </Button>
          <Button
            onClick={() => {
              applyAddMutePages(pageId, 0);
            }}
            shape="circle">
            <DeleteOutlined style={{ color: "#E33636" }} />
          </Button>
        </Row>
      </div>
    );
  } else if (mutePagesAfter > 0) {
    content = (
      <div className={style.addingMute} onClick={() => setIsAddingMute(true)}>
        <div>
          <InputNumber size="small" disabled={true} defaultValue={mutePagesAfter} />
          <span>{t(`${i18nKey}.mutePage`)}</span>
        </div>
        <Row>
          <span>{pageNumber + 1}</span>
          <span>{"->"}</span>
          <span>{pageNumber + mutePageNumber}</span>
          <span>/{displayedTotalPageNumber}</span>
        </Row>
      </div>
    );
  } else {
    content = (
      <>
        <button disabled={isLast} onClick={() => mergePages(pageId)}>
          <MergePagesIcn />
        </button>
        <button onClick={() => setIsAddingMute(true)}>
          <MutePageIcn />
        </button>
      </>
    );
  }

  return (
    <div
      className={style.pageBar}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      {content}
    </div>
  );
}
