import { Button, Checkbox, Form, Modal, notification, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { ExportType } from "../../../../../../../__generated__/globalTypes";
import { useI18n } from "../../../../../../i18n";
import { useExportProject } from "../../../../Project/api/api";
import { buildColorationConfigFromArrayOfString } from "../../../../utils/common";

type Props = {
  id?: string;
  name?: string;
  isEPubExport?: boolean;
  onExit: () => void;
};

const ExportModal = ({ id, name, isEPubExport, onExit }: Props) => {
  const isVisible = !!id && !!name;
  const [t] = useI18n();

  const [includeAudio, setIncludeAudio] = useState(true);

  const colorationOptions = [
    {
      value: "syllable",
      label: t("project.existing.index.syllableComponent.syllables"),
    },
    {
      value: "phoneme",
      label: t("project.existing.index.syllableComponent.phonemes"),
    },
    {
      value: "custom",
      label: t("project.existing.index.syllableComponent.custom"),
    },
  ];

  const [selectedColorationsOptions, setSelectedColorationsOptions] = useState<string[]>(
    colorationOptions.map((o) => o.value),
  );

  const [exportProject] = useExportProject();

  const callExportProject = useCallback(async () => {
    try {
      if (!id || !name) {
        return;
      }

      notification.info({
        message: t("library.index.default.export.message"),
      });
      onExit();

      const _includeAudio = isEPubExport ? false : includeAudio;

      await exportProject({
        variables: {
          projectId: id,
          exportType: isEPubExport ? ExportType.INTO_IMPORTED_EPUB : ExportType.FULL,
          exportConfig: {
            coloration: buildColorationConfigFromArrayOfString(selectedColorationsOptions),
            includeAudio: _includeAudio,
          },
        },
      });
    } catch (e) {
      console.error(e);
      notification.error({
        message: t("library.index.default.export.errorNotification"),
      });
    }
  }, [id, name, t, onExit, isEPubExport, includeAudio, selectedColorationsOptions, exportProject]);

  // Effect for default values
  useEffect(() => {
    if (!isVisible) {
      setIncludeAudio(true);
    }
  }, [isVisible]);

  return (
    <Modal destroyOnClose open={isVisible} footer={false} onCancel={onExit}>
      {!!id && !!name && (
        <>
          <Form
            name="basic"
            initialValues={{ remember: false }}
            onFinish={callExportProject}
            layout="vertical">
            <Form.Item
              style={{ padding: "0 7em" }}
              label={t("library.index.default.export.colorationOptions")}
              name="title"
              initialValue={colorationOptions.map((o) => o.value)}>
              <Checkbox.Group
                options={colorationOptions}
                onChange={(values) =>
                  setSelectedColorationsOptions(values.map((v) => v.toLocaleString()))
                }
              />
            </Form.Item>
          </Form>
          <Form
            name="basic"
            initialValues={{ remember: false }}
            onFinish={callExportProject}
            layout="vertical">
            {!isEPubExport && (
              <Form.Item
                style={{ padding: "0 7em" }}
                label={t("library.index.default.export.audioOption")}
                name="title">
                <div style={{ marginTop: "-1.85em" }}>
                  <Checkbox
                    checked={includeAudio}
                    onChange={(e) => setIncludeAudio(e.target.checked)}>
                    {t("library.index.default.export.audioOptionCheckbox")}
                  </Checkbox>
                </div>
              </Form.Item>
            )}
            <Form.Item style={{ margin: 0 }}>
              <Row justify="center">
                <Button htmlType="submit" shape="round">
                  {t("library.index.projectCard.menu.export")}
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </>
      )}
    </Modal>
  );
};

export default ExportModal;
