import "./editor/styles/index.less";
import "../i18n/index";
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import React from "react";
import { ConfigProvider, Layout } from "antd";
import { ApolloProvider } from "@apollo/client";
import Library from "./editor/Library/ProjectsPage/ProjectsPage";
import { createRoot } from "react-dom/client";
import Book from "./editor/Project";
import "antd/dist/reset.css";
import apolloClient from "../apollo";
import { NarrationHome } from "./narration/pages/narrationHome/NarrationHome";
import { MemoizedBook } from "./narration/pages/narrationBookPage/NarrationBookPage";
import {
  SelectedRoleProvider,
  useSelectedRoleReducer,
  FrogRoles,
  useSelectedRole,
  useRedirectOnRoleChange,
} from "../identity";
import EnvBanner from "../components/EnvBanner/EnvBanner";
import { antDesignTheme } from "./editor/styles/antDesignTheme";
import { supabaseClient, SupabaseProvider } from "../authentication/supabase";

export const client = apolloClient(supabaseClient);

const RoutesWithRole = () => {
  const roleReducer = useSelectedRoleReducer();
  return (
    <SelectedRoleProvider value={roleReducer}>
      <ProtectedRoutes />
    </SelectedRoleProvider>
  );
};

const ProtectedRoutes = () => {
  const { state: selectedRole } = useSelectedRole();

  useRedirectOnRoleChange(selectedRole);

  const redirectTo =
    selectedRole === FrogRoles.Editor ? (
      <Navigate to="/editor/library" replace />
    ) : selectedRole === FrogRoles.Narrator || selectedRole === FrogRoles.ProofListener ? (
      <Navigate to="/audio/library" replace />
    ) : (
      <Navigate to="/error" replace />
    );

  return (
    <Routes>
      <Route path="/" element={redirectTo} />

      <Route path="/editor/books/edit/:bookId/*" Component={Book} />
      <Route path="/editor/library" Component={Library} />
      <Route path="/audio/library" Component={NarrationHome} />
      <Route path="/audio/library/edit/:bookId/:page/*" Component={MemoizedBook} />
      <Route path="/error" element={<div>Error</div>} />
    </Routes>
  );
};

const EntryPoint = () => {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ConfigProvider theme={antDesignTheme}>
          <EnvBanner />
          <Layout style={{ height: "100vh" }}>
            <SupabaseProvider>
              <RoutesWithRole />
            </SupabaseProvider>
          </Layout>
        </ConfigProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
};

window.addEventListener("load", () =>
  createRoot(document.getElementById("app")!).render(<EntryPoint />),
);
