/* eslint-disable react/display-name */
import "../styles/index.less";
import { Route, Routes, useMatch, useMatches, useParams } from "react-router-dom";
import React, { CSSProperties, useMemo } from "react";
import { Layout, Spin } from "antd";
import ExistingProject from "./components/EditingRouter/EditingRouterProvider";
import ActionsBar from "./components/ActionsBar";
import Search, { SearchContext, useSearchReducer } from "./components/Search";
import { usePages, UnfoldedPageCtx, useUnfoldedPagesReducer } from "./components/Page";
import useProjectsData from "../Library/ProjectsPage/hooks/useProjectsData";
import SubRouteNotImported from "./components/EditingRouter/subroutes/SubRouteNotImported";

import { ExtendedDefinitionKind, ImportStatus } from "../../../../__generated__/globalTypes";

const { Content } = Layout;

export const Spinner = ({ style }: { style?: CSSProperties }) => (
  <div
    style={{
      ...style,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}>
    <Spin />
  </div>
);

export type Definition = {
  id: string;
  kind: ExtendedDefinitionKind;
  definition: string;
  imageName: string | null;
  insertOrder: number;
};

export default React.memo(() => {
  const matchesBookEdit = useMatch("/editor/books/edit/:bookId/*");
  const { bookId } = useParams<{ bookId: string }>();
  if (!bookId) {
    throw new Error("No bookId provided");
  }

  const unfoldedPages = useUnfoldedPagesReducer();
  const search = useSearchReducer();
  const { projects: projectsData, loading: projectsLoading } = useProjectsData({
    filterString: "",
  });

  const project = useMemo(() => {
    if (projectsData.length) {
      return projectsData?.find((p) => p.id === bookId);
    }
  }, [bookId, projectsData]);

  const { pages, loading } = usePages(bookId!, projectsLoading || project?.importStatus !== "DONE");

  if (project?.importStatus === ImportStatus.FAILED) {
    return <SubRouteNotImported failed />;
  }

  if (
    project?.importStatus === ImportStatus.WAITING_FOR_CONTENT ||
    project?.importStatus === ImportStatus.IMPORTING_CONTENT
  ) {
    return <SubRouteNotImported notImportedYet />;
  }

  if (!project || loading || projectsLoading) {
    return <SubRouteNotImported loading />;
  }

  return (
    <SearchContext.Provider value={search.state}>
      <Layout id="project-root" style={{ overflow: "auto" }}>
        <Content
          className="site-layout-background"
          style={{
            margin: 0,
            flex: 1,
          }}>
          {
            <UnfoldedPageCtx.Provider
              value={{
                send: unfoldedPages.send,
                unfoldedPages: unfoldedPages.state,
              }}>
              <ExistingProject pages={pages} loading={loading} projectId={bookId} />
            </UnfoldedPageCtx.Provider>
          }
        </Content>
      </Layout>
      {!!pages.length && (
        <>
          <Search
            pages={pages}
            bookId={bookId}
            searchMode={search.state.searchMode}
            setSearchMode={search.send.setSearchMode}
            isOpen={search.state.isOpen}
            close={search.send.close}
            setResults={search.send.setResults}
          />
          {matchesBookEdit ? (
            <ActionsBar
              loading={loading}
              projectId={bookId}
              isSearchOpen={search.state.isOpen}
              toggleSearch={search.send.toggle}
            />
          ) : null}
        </>
      )}
    </SearchContext.Provider>
  );
});
