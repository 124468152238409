import React, { createContext, useContext } from "react";
import { usePersistedReducer } from "../../../../../persistence/usePersistedReducer";
import { STORAGE_KEY } from "./config";
import reducer, { initialState } from "./reducer";

const SpeedControlsContext = createContext<
  | {
      state: typeof initialState;
      dispatch: React.Dispatch<any>;
    }
  | undefined
>(undefined);

export const SpeedControlsProvider = ({ children }: { children: React.ReactNode }) => {
  const store = usePersistedReducer(reducer, initialState, STORAGE_KEY);

  return <SpeedControlsContext.Provider value={store}>{children}</SpeedControlsContext.Provider>;
};

export const useSpeedControlsStore = () => {
  const context = useContext(SpeedControlsContext);
  if (!context) throw new Error("SpeedControlsContext must be used within a SpeedControlsProvider");
  return context;
};
