import React, { useCallback } from "react";
import { SubToPages_pages } from "../../../api/SubToPages";
import SyllableComponent from "../components/SyllableComponent";
import {
  useCreateSyllableFromPosition,
  useDeleteSyllableFromPosition,
  useUnsetMuteFromPosition,
  setMuteFromPosition as useSetMuteFromPosition,
  useCreateColorationFromPosition,
  useDeleteColorationFromPosition,
  useUnsetPhonemeFromPosition,
  useSetPhonemeFromPosition,
} from "../../../api/api";
import { SubToProject_project } from "../../../api/__generated__/SubToProject";
import { SelectedTextPosition } from "../../EditingModes/Formatting/components/FormattingComp/FormattingComp";
import {
  AddExtendedMetadataCustomColoration,
  ExtendedPhonemeKind,
} from "../../../../../../../__generated__/globalTypes";

type Props = {
  allPages: SubToPages_pages[];
  project: SubToProject_project;
};

export function PaginatedSyllable({ allPages, project }: Props) {
  const [applyCreateSyllableFromPosition] = useCreateSyllableFromPosition();
  const [applyDeleteSyllableFromPosition] = useDeleteSyllableFromPosition();
  const [applySetMutedFromPosition] = useSetMuteFromPosition();
  const [applyUnsetMuteFromPosition] = useUnsetMuteFromPosition();
  const [applySetPhonemeFromPosition] = useSetPhonemeFromPosition();
  const [applyUnsetPhonemeFromPosition] = useUnsetPhonemeFromPosition();
  const [applyCreateColorationFromPosition] = useCreateColorationFromPosition();
  const [applyDeleteColorationFromPosition] = useDeleteColorationFromPosition();

  const removePhoneme = useCallback(
    (selectedTextPosition: SelectedTextPosition) =>
      applyUnsetPhonemeFromPosition({
        variables: {
          projectId: project.id,
          size: selectedTextPosition.size,
          start: selectedTextPosition.start,
          stringVersion: allPages[0].stringVersion,
        },
      }),
    [applyUnsetPhonemeFromPosition, project, allPages],
  );

  const assignPhoneme = useCallback(
    (selectedTextPosition: SelectedTextPosition, phoneme: ExtendedPhonemeKind) =>
      applySetPhonemeFromPosition({
        variables: {
          projectId: project.id,
          size: selectedTextPosition.size,
          start: selectedTextPosition.start,
          stringVersion: allPages[0].stringVersion,
          kind: phoneme,
        },
      }),
    [applySetPhonemeFromPosition, project, allPages],
  );

  const removeCustomColor = useCallback(
    (selectedTextPosition: SelectedTextPosition) =>
      applyDeleteColorationFromPosition({
        variables: {
          projectId: project.id,
          size: selectedTextPosition.size,
          start: selectedTextPosition.start,
          stringVersion: allPages[0].stringVersion,
        },
      }),
    [applyDeleteColorationFromPosition, project, allPages],
  );

  const assignCustomColor = useCallback(
    (selectedTextPosition: SelectedTextPosition, coloration: AddExtendedMetadataCustomColoration) =>
      applyCreateColorationFromPosition({
        variables: {
          projectId: project.id,
          size: selectedTextPosition.size,
          start: selectedTextPosition.start,
          stringVersion: allPages[0].stringVersion,
          newColoration: coloration,
        },
      }),
    [applyCreateColorationFromPosition, project, allPages],
  );

  const removeSyllable = useCallback(
    async (selectedTextPosition: SelectedTextPosition) => {
      await applyDeleteSyllableFromPosition({
        variables: {
          projectId: project.id,
          size: selectedTextPosition.size,
          start: selectedTextPosition.start,
          stringVersion: allPages[0].stringVersion,
        },
      });
    },
    [applyDeleteSyllableFromPosition, project, allPages],
  );

  const mergeSyllables = useCallback(
    async (selectedTextPosition: SelectedTextPosition) => {
      await applyCreateSyllableFromPosition({
        variables: {
          projectId: project.id,
          size: selectedTextPosition.size,
          start: selectedTextPosition.start,
          stringVersion: allPages[0].stringVersion,
        },
      });
    },
    [applyCreateSyllableFromPosition, project, allPages],
  );

  const muteLetters = useCallback(
    (selectedTextPosition: SelectedTextPosition) => {
      applySetMutedFromPosition({
        variables: {
          projectId: project.id,
          size: selectedTextPosition.size,
          start: selectedTextPosition.start,
          stringVersion: allPages[0].stringVersion,
        },
      });
    },
    [applySetMutedFromPosition, project, allPages],
  );

  const unmuteLetters = useCallback(
    (selectedTextPosition: SelectedTextPosition) => {
      applyUnsetMuteFromPosition({
        variables: {
          projectId: project.id,
          size: selectedTextPosition.size,
          start: selectedTextPosition.start,
          stringVersion: allPages[0].stringVersion,
        },
      });
    },
    [applyUnsetMuteFromPosition, project, allPages],
  );

  return (
    <SyllableComponent
      componentProps={{
        removeSyllable: removeSyllable,
        merge: mergeSyllables,
        mute: muteLetters,
        unmute: unmuteLetters,
        removePhoneme,
        assignPhoneme,
        customColors: Object.entries(project?.customColorationConfig).map(([key, value]) => ({
          key,
          ...(JSON.parse(value as string) as {
            label: string;
            color: string;
          }),
        })),
        removeCustomColor,
        assignCustomColor,
        projectId: project.id,
        allPages,
      }}
      pages={allPages}
      projectId={project.id}
    />
  );
}
