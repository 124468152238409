import { createContext, Dispatch, useContext, useEffect, useMemo, useReducer } from "react";
import { jwtDecode } from "jwt-decode";
import { useLocation, useNavigate } from "react-router";
import { useSupabase } from "../authentication/supabase";
import { User } from "@supabase/supabase-js";

export type UserProfile = {
  display_name: string;
  email: string;
  first_name: string | null;
  last_name: string | null;
  phone: string | null;
};

export const useIdentity = () => {
  const session = useSupabase();
  const idToken = session.access_token;
  if (!idToken || session.user.aud !== "authenticated") {
    throw new Error("Shouldn't be called on unauthenticated user");
  }
  const decodedToken = jwtDecode(idToken) as any;

  return useMemo(
    (): User & { roles: ROLES[]; user_profile: UserProfile } => ({
      ...session.user,
      roles: decodedToken.roles,
      user_profile: decodedToken.user_profile,
    }),
    [idToken],
  );
};

export enum ROLES {
  CONTENT_ADMINISTRATOR = "CONTENT_ADMINISTRATOR",
  NARRATOR = "NARRATOR",
  PROOF_LISTENER = "PROOF_LISTENER",
  EDITOR = "EDITOR",
}

export const useIsContentAdministrator = () => {
  const identity = useIdentity();

  return useMemo(
    () => !!identity?.roles.find((group) => group === ROLES.CONTENT_ADMINISTRATOR),
    [identity],
  );
};

const SelectedRoleContext = createContext<{
  state: FrogRoles;
  dispatch: Dispatch<FrogRoles>;
} | null>(null);

export const SelectedRoleProvider = SelectedRoleContext.Provider;

export const useSelectedRole = () => {
  const context = useContext(SelectedRoleContext);
  if (!context) {
    throw new Error("Selected role context has to be set in a provider");
  }

  return context;
};

export enum FrogRoles {
  Narrator = "narrator",
  ProofListener = "prooflistener",
  Editor = "editor",
}

enum FrogModes {
  Audio = "audio",
  Editor = "editor",
}

const ROLE_KEY = "ROLE";

export function useCanSwitchRole() {
  const identity = useIdentity();
  const roles = identity.roles;

  return roles.length > 1;
}

export function useIsAdmin() {
  return !!useIdentity().roles.includes(ROLES.CONTENT_ADMINISTRATOR);
}

export function useIsEditor() {
  return !!useIdentity().roles.includes(ROLES.EDITOR);
}

export function useIsNarrator() {
  return !!useIdentity().roles.includes(ROLES.NARRATOR);
}

export function useIsProofListener() {
  return !!useIdentity().roles.includes(ROLES.PROOF_LISTENER);
}

function useStoredRole() {
  return sessionStorage.getItem(ROLE_KEY) as FrogRoles | null;
}

function useStoreRole(role: FrogRoles) {
  useEffect(() => sessionStorage.setItem(ROLE_KEY, role), [role]);
}

export function useRedirectOnRoleChange(role: FrogRoles) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const newMode = role === FrogRoles.Editor ? FrogModes.Editor : FrogModes.Audio;
    let currentMode = null;

    if (location.pathname.includes(FrogModes.Editor)) {
      currentMode = FrogModes.Editor;
    } else if (location.pathname.includes(FrogModes.Audio)) {
      currentMode = FrogModes.Audio;
    }

    const pathname = location.pathname;
    const isUsefulToRedirect =
      currentMode !== null &&
      (pathname.includes(FrogModes.Editor) || pathname.includes(FrogModes.Audio));

    if (currentMode !== null && currentMode !== newMode && isUsefulToRedirect) {
      navigate(location.pathname.replace(currentMode, newMode), { replace: true });
    }
  }, [role, location.pathname]); // Only depend on role changes, not pathname
}

const useGetRoleFromURL = (storedRole: FrogRoles | null) => {
  const location = useLocation();
  const isEditor = useIsEditor();
  const isNarrator = useIsNarrator();
  const isProofListener = useIsProofListener();

  // When launching on home page
  const isHomePage = location.pathname === "/";
  if (isHomePage) {
    if (storedRole) {
      return storedRole;
    }
    // Default role
    if (isEditor) {
      return FrogRoles.Editor;
    }
    if (isNarrator) {
      return FrogRoles.Narrator;
    }
    if (isProofListener) {
      return FrogRoles.ProofListener;
    }
  }

  // When launching on non-home page, the role will be either given from the stored one, or from from the url
  const modeFromUrl = location.pathname.includes(FrogModes.Editor)
    ? FrogModes.Editor
    : FrogModes.Audio;

  if (modeFromUrl === FrogModes.Editor && isEditor) {
    return FrogRoles.Editor;
  }
  if (modeFromUrl === FrogModes.Audio) {
    if (storedRole === FrogRoles.Narrator || storedRole === FrogRoles.ProofListener) {
      return storedRole;
    }
    if (isNarrator) {
      return FrogRoles.Narrator;
    }
    if (isProofListener) {
      return FrogRoles.ProofListener;
    }
  }

  return null;
};

export function useDefaultRole() {
  const isEditor = useIsEditor();
  const isNarrator = useIsNarrator();
  const isProofListener = useIsProofListener();
  const storedRole = useStoredRole();
  const roleFromUrl = useGetRoleFromURL(storedRole);

  if (roleFromUrl) {
    return roleFromUrl;
  }
  if (storedRole) return storedRole;

  if (isEditor) return FrogRoles.Editor;
  if (isNarrator) return FrogRoles.Narrator;
  if (isProofListener) return FrogRoles.ProofListener;

  throw new Error("Shoulh have at least one role");
}

export const useSelectedRoleReducer = () => {
  const defaultRole = useDefaultRole();
  const [state, dispatch] = useReducer((_: FrogRoles, message: FrogRoles) => message, defaultRole);

  useStoreRole(state);
  return useMemo(() => ({ state, dispatch }), [state, dispatch]);
};
