import React, { useCallback } from "react";
import { Button, Form, FormInstance, Input, Modal, Radio, Row } from "antd";
import { ImageType } from "./Image";
import { useUpdateImage } from "../../../../../../api/api";
import { normalizeStr } from "../../../../../../../utils/common";
import { radioStyle } from "../../..";
import { useI18n } from "../../../../../../../../../i18n";
import { ImageKind } from "../../../../../../../../../../__generated__/globalTypes";

const i18nKeyForm = "project.existing.editingMode.formatting.default.form";

const RadioButton = Radio.Button;

const stringOrEmptyString = (str: string | undefined | null): string =>
  typeof str === "string" && str.trim() !== "" ? str : "";

const ModalEditImage = ({
  isEditingImage,
  setIsEditingImage,
  form,
  projectId,
}: {
  isEditingImage: ImageType & { open: boolean };
  setIsEditingImage: Function;
  form: FormInstance<any>;
  projectId: string;
}) => {
  const [t] = useI18n();
  const [apolloUpdateImage] = useUpdateImage();

  const applyUpdateImage = useCallback(
    (image: ImageType) => {
      apolloUpdateImage({
        variables: {
          imageId: image.id,
          fileName: normalizeStr(image.fileName),
          kind: image.kind,
          position: image.position,
          description: stringOrEmptyString(image.description),
          figcaption: stringOrEmptyString(image.figcaption),
          projectId,
        },
      });
      setIsEditingImage({
        open: false,
        id: image.id,
        fileName: normalizeStr(image.fileName),
        description: stringOrEmptyString(image.description),
        figcaption: stringOrEmptyString(image.figcaption),
        kind: image.kind,
      });
    },
    [apolloUpdateImage, setIsEditingImage, projectId],
  );

  return (
    <Modal
      open={isEditingImage.open}
      footer={false}
      onCancel={() =>
        setIsEditingImage({
          ...isEditingImage,
          open: false,
        })
      }>
      <Form
        key={isEditingImage.id}
        name={t(`${i18nKeyForm}.name`)}
        onFinish={({ fileName, kind, description, figcaption }: ImageType) => {
          const updatedImage = {
            fileName: fileName || isEditingImage.fileName,
            kind: kind || isEditingImage.kind,
            description: description,
            figcaption: figcaption,
          };
          applyUpdateImage({
            ...isEditingImage,
            ...updatedImage,
          });
        }}
        layout="vertical"
        form={form}>
        <Form.Item
          style={{ padding: "0 7em" }}
          label={t(`${i18nKeyForm}.imageNaming.label`)}
          name="fileName">
          <Input />
        </Form.Item>
        <Form.Item
          style={{ padding: "0 7em" }}
          label={t(`${i18nKeyForm}.imageDescription.label`)}
          name="description">
          <Input />
        </Form.Item>
        <Form.Item
          style={{ padding: "0 7em" }}
          label={t(`${i18nKeyForm}.figcaption.label`)}
          name="figcaption">
          <Input />
        </Form.Item>
        <Form.Item style={{ margin: 0 }} name="kind">
          <Row justify="center">
            <Radio.Group
              style={{
                display: "flex",
                fontSize: "1.25em",
              }}
              optionType="button">
              <RadioButton style={radioStyle} value={ImageKind.NORMAL}>
                {t(`${i18nKeyForm}.radioButton.normal`)}
              </RadioButton>
              <RadioButton style={radioStyle} value={ImageKind.POPUP}>
                {t(`${i18nKeyForm}.radioButton.popup`)}
              </RadioButton>
            </Radio.Group>
          </Row>
        </Form.Item>
        <Form.Item style={{ margin: 0, marginTop: "2em" }}>
          <Row justify="center">
            <Button htmlType="submit" shape="round">
              {t(`${i18nKeyForm}.validation`)}
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalEditImage;
