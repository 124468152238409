import { preciseFloat } from "../../../../Utils/common";
import {
  SubscribeToExtendedPage_extendedPage_rhese,
  SubscribeToExtendedPage_extendedPage_rhese_data_audioInfo,
} from "../../../../../editor/Project/api/__generated__/SubscribeToExtendedPage";
import { isNumber } from "lodash";

export const getAudioOffsetsAndDuration = (
  audioRhese?: SubscribeToExtendedPage_extendedPage_rhese,
  round?: number,
): {
  duration: number;
  start: number | null;
  end: number | null;
  startOffset: number | null;
  endOffset: number | null;
  isCropped: boolean;
} => {
  if (!audioRhese) {
    return {
      duration: 0,
      start: null,
      end: null,
      startOffset: null,
      endOffset: null,
      isCropped: false,
    };
  }
  let { start, end } = audioRhese.data.audioInfo || {};
  const { humanStart, humanEnd } = audioRhese.data.audioInfo || {};

  const hasHumanStart = isNumber(humanStart);
  const hasHumanEnd = isNumber(humanEnd);

  if (hasHumanStart) {
    start = humanStart;
  }

  if (hasHumanEnd) {
    end = humanEnd;
  }

  if (!isNumber(start) || !isNumber(end)) {
    return {
      duration: 0,
      start: null,
      end: null,
      startOffset: null,
      endOffset: null,
      isCropped: false,
    };
  }

  // If the audio is cropped
  if (hasHumanStart || hasHumanEnd) {
    let startOffset = null;
    let endOffset = null;
    let duration = 0;

    // Determine startOffset
    if (hasHumanStart) {
      startOffset = preciseFloat(humanStart - start, 2);
    }
    // Determine endOffset
    if (hasHumanEnd) {
      endOffset = preciseFloat(humanEnd - end, 2);
    }

    // Determine duration
    if (hasHumanStart && hasHumanEnd) {
      duration = humanEnd - humanStart;
    } else if (!hasHumanEnd && hasHumanStart) {
      duration = end - humanStart;
    } else if (hasHumanEnd) {
      duration = humanEnd - start;
    }

    return {
      duration: preciseFloat(duration, 2),
      start: preciseFloat(start, 2),
      end: preciseFloat(end, 2),
      startOffset,
      endOffset,
      isCropped: true,
    };
  } else {
    const duration = end - start;
    return {
      duration: round ? preciseFloat(duration, round) : duration,
      start: preciseFloat(start, 2),
      end: preciseFloat(end, 2),
      startOffset: null,
      endOffset: null,
      isCropped: false,
    };
  }
};

export const hasHumanStart = (
  audioInfo?: SubscribeToExtendedPage_extendedPage_rhese_data_audioInfo,
) => {
  if (!audioInfo) {
    return false;
  }
  return (
    audioInfo?.humanStart !== -1 &&
    audioInfo?.humanStart !== null &&
    audioInfo?.humanStart !== undefined
  );
};

export const hasHumanEnd = (
  audioInfo?: SubscribeToExtendedPage_extendedPage_rhese_data_audioInfo,
) => {
  if (!audioInfo) {
    return false;
  }
  return (
    audioInfo?.humanEnd !== -1 &&
    audioInfo?.humanEnd !== null &&
    audioInfo?.humanStart !== undefined
  );
};
