import { useState, useEffect } from "react";
import { createClient, Session } from "@supabase/supabase-js";
import React from "react";
import LogoFrogEditor from "../app/narration/Assets/LogoFrogEditor";

import * as style from "./style.less";
import { Button, Card, Input, Space } from "antd";

export const supabaseClient = createClient(process.env.SUPABASE_URL!, process.env.SUPABASE_KEY!);

const SupabaseContext = React.createContext<Session | null>(null);

async function signInWithMagicLink(email: string) {
  const { error } = await supabaseClient.auth.signInWithOtp({
    email,
    options: {
      emailRedirectTo: window.location.origin,
    },
  });

  if (error) throw error;
}

export function useSupabase() {
  const session = React.useContext(SupabaseContext);
  if (!session) {
    throw new Error("useSupabase must be used within a SupabaseProvider");
  }
  return session;
}

export function SupabaseProvider({ children }: { children: React.ReactNode }) {
  const [session, setSession] = useState<Session | null>(null);
  const [mailHasBeenSent, setMailHasBeenSent] = useState(false);

  useEffect(() => {
    supabaseClient.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });
    const {
      data: { subscription },
    } = supabaseClient.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
    return () => subscription.unsubscribe();
  }, []);

  if (!session && !mailHasBeenSent) {
    // add a button to send magic link using antd

    return (
      <div className={style.container}>
        <Card
          id="login"
          bordered
          title={
            <div className={style.title}>
              <div className={style.logo}>
                <LogoFrogEditor />
              </div>
              Please sign in with your email
            </div>
          }>
          <Space direction="vertical" size="large">
            <Input size="small" id="email" type="email" placeholder="Email" />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                size="large"
                onClick={() => {
                  const input = document.querySelector("#email") as HTMLInputElement;
                  signInWithMagicLink(input.value);
                  setMailHasBeenSent(true);
                }}>
                Send Magic Link
              </Button>
            </div>
          </Space>
        </Card>
      </div>
    );
  }
  if (!session && mailHasBeenSent) {
    return (
      <div className={style.container}>
        <div>
          <p>
            Please check your email and click the Magic link ! Email not received ? Check your spam
            box…
          </p>
        </div>
      </div>
    );
  }
  return <SupabaseContext.Provider value={session}>{children}</SupabaseContext.Provider>;
}
