@import "../app/editor/styles/colors.less";

.container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

:global(#login .ant-card-head) {
  padding: 1em 1.5em;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 1em;

  svg {
    fill: #fff;
  }

  .logo {
    padding: 0.5em 1em;
    background-color: @dark-blue;
    border-radius: 6px;
    text-align: center;
  }
}
