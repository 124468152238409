import React, { useMemo, useEffect, useCallback, useRef } from "react";
import { Menu, Dropdown } from "antd";
import * as style from "./style.less";

import bookRenderer from "../../BookRenderer";
import { useI18nObjectHook } from "../../../../../../i18n";
import { getMenuItem, MenuItem } from "../../../../styles/antDesignTheme";
import { SubscribeToExtendedPage_extendedPage } from "../../../api/__generated__/SubscribeToExtendedPage";
import { SelectedTextPosition } from "../Formatting/components/FormattingComp/FormattingComp";
import {
  AddExtendedMetadataCustomColoration,
  ExtendedPhonemeKind,
} from "../../../../../../../__generated__/globalTypes";
import { usePageData } from "../../../hooks/usePageData";
import { SubToPages_pages } from "../../../api/SubToPages";
import { useTextPosition } from "../../../hooks/useTextPosition";

type AssignPhoneme = (
  selectedTextPosition: SelectedTextPosition,
  phoneme: ExtendedPhonemeKind,
) => void;
type AssignCustomColor = (
  selectedTextPosition: SelectedTextPosition,
  coloration: AddExtendedMetadataCustomColoration,
) => void;

type RemovePhoneme = (selectedTextPosition: SelectedTextPosition) => void;
type RemoveSyllable = (selectedTextPosition: SelectedTextPosition) => void;
type RemoveCustomColor = (selectedTextPosition: SelectedTextPosition) => void;

type Merge = (selectedTextPosition: SelectedTextPosition) => void;
type Mute = (selectedTextPosition: SelectedTextPosition) => void;
type Unmute = (selectedTextPosition: SelectedTextPosition) => void;

export type BuildSyllableMenuTranslationProps = {
  creat: string;
  transformMute: string;
  transformNoMute: string;
  ignore: string;
};

export type BuildPhonemeMenuTranslationProps = {
  title: string;
  vowel: string;
  consonant: string;
  complex: string;
  transformMute: string;
  transformNoMute: string;
  ignore: string;
};

export type BuildCustomColorMenuTranslationProps = {
  assignCustomColor: string;
  removeCustomColor: string;
};

export type Props = {
  mode: "syllables" | "phonemes" | "custom";
  removeSyllable: RemoveSyllable;
  merge: Merge;
  mute: Mute;
  unmute: Unmute;
  assignPhoneme: AssignPhoneme;
  removePhoneme: RemovePhoneme;
  removeCustomColor: RemoveCustomColor;
  assignCustomColor: AssignCustomColor;
  customColors: CustomColor[];
  projectId: string;
  allPages: SubToPages_pages[];
};

export const buildSyllableMenu = (
  selectedTextPosition: SelectedTextPosition,
  removeSyllable: RemoveSyllable,
  merge: Merge,
  mute: Mute,
  unmute: Unmute,
  translation: BuildSyllableMenuTranslationProps,
) => {
  const isDisabled = selectedTextPosition.size === 0;

  const items: MenuItem[] = [
    getMenuItem({
      key: "creat",
      label: translation.creat,
      disabled: isDisabled,
      onClick: selectedTextPosition.size
        ? () => {
            if (!isDisabled) {
              merge(selectedTextPosition);
            }
          }
        : undefined,
    }),
    getMenuItem({
      key: "transformMute",
      label: translation.transformMute,
      disabled: isDisabled,
      onClick: selectedTextPosition.size
        ? () => {
            if (!isDisabled) {
              mute(selectedTextPosition);
            }
          }
        : undefined,
    }),
    getMenuItem({
      key: "transformNoMute",
      label: translation.transformNoMute,
      disabled: isDisabled,
      onClick: selectedTextPosition.size
        ? () => {
            if (!isDisabled) {
              unmute(selectedTextPosition);
            }
          }
        : undefined,
    }),
    getMenuItem({
      key: "ignore",
      label: translation.ignore,
      danger: true,
      disabled: isDisabled,
      onClick: selectedTextPosition.size
        ? () => {
            if (!isDisabled) {
              removeSyllable(selectedTextPosition);
            }
          }
        : undefined,
    }),
  ];

  return <Menu items={items} />;
};

export const buildPhonemeMenu = (
  selectedTextPosition: SelectedTextPosition,
  removePhoneme: RemoveSyllable,
  assignPhoneme: AssignPhoneme,
  mute: Mute,
  unmute: Unmute,
  translation: BuildPhonemeMenuTranslationProps,
) => {
  const isDisabled = !selectedTextPosition.size;

  const items: MenuItem[] = [
    getMenuItem({
      key: "s1",
      label: translation.title,
      disabled: isDisabled,
      children: [
        getMenuItem({
          key: "s1-1",
          label: translation.vowel,
          onClick: () => {
            if (!isDisabled) {
              assignPhoneme(selectedTextPosition, "VOWEL" as ExtendedPhonemeKind);
            }
          },
        }),
        getMenuItem({
          key: "s1-2",
          label: translation.consonant,
          onClick: () => {
            if (!isDisabled) {
              assignPhoneme(selectedTextPosition, "CONSONANT" as ExtendedPhonemeKind);
            }
          },
        }),
        getMenuItem({
          key: "s1-3",
          label: translation.complex,
          onClick: () => {
            if (!isDisabled) {
              assignPhoneme(selectedTextPosition, "COMPLEX" as ExtendedPhonemeKind);
            }
          },
        }),
      ],
    }),
    getMenuItem({
      key: "2",
      label: translation.transformMute,
      disabled: isDisabled,
      onClick: () => {
        if (!isDisabled) {
          mute(selectedTextPosition);
        }
      },
    }),
    getMenuItem({
      key: "3",
      label: translation.transformNoMute,
      disabled: isDisabled,
      onClick: () => {
        if (!isDisabled) {
          unmute(selectedTextPosition);
        }
      },
    }),
    getMenuItem({
      key: "4",
      danger: true,
      label: translation.ignore,
      disabled: isDisabled,
      onClick: () => {
        if (!isDisabled) {
          removePhoneme(selectedTextPosition);
        }
      },
    }),
  ];

  return <Menu items={items} />;
};

type CustomColor = {
  key: string;
  label: string;
  color: string;
};

export const buildCustomColorMenu = (
  selectedTextPosition: SelectedTextPosition,
  removeCustomColor: RemoveCustomColor,
  assignCustomColor: AssignCustomColor,
  customColors: CustomColor[],
  translation: BuildCustomColorMenuTranslationProps,
) => {
  const isDisabled = !selectedTextPosition.size;

  const items: MenuItem[] = [
    getMenuItem({
      key: "s1",
      label: translation.assignCustomColor,
      disabled: isDisabled,
      children: customColors
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((customColor) =>
          getMenuItem({
            key: customColor.key,
            label: customColor.label,
            onClick: () => {
              if (!isDisabled) {
                assignCustomColor(selectedTextPosition, { customColorationKey: customColor.key });
              }
            },
          }),
        ),
    }),
    getMenuItem({
      key: "2",
      label: translation.removeCustomColor,
      disabled: isDisabled,
      onClick: () => {
        if (!isDisabled) {
          removeCustomColor(selectedTextPosition);
        }
      },
    }),
  ];

  return <Menu items={items} />;
};

export default function Syllable({
  merge,
  mute,
  unmute,
  removeSyllable,
  mode,
  removePhoneme,
  assignPhoneme,
  removeCustomColor,
  assignCustomColor,
  customColors,
  projectId,
  allPages,
}: Props) {
  const { pageMetadata } = usePageData(allPages);

  const contentEditableRef = useRef<HTMLDivElement>(null);
  const RenderBook = useMemo(() => bookRenderer(), []);
  const { selectedTextPosition, reset, saveSelection } = useTextPosition();

  const buildSyllableMenuTranslation: BuildSyllableMenuTranslationProps = useI18nObjectHook(
    "project.existing.editingMode.syllable.buildSyllableMenu",
  );
  const buildPhonemeMenuTranslation: BuildPhonemeMenuTranslationProps = useI18nObjectHook(
    "project.existing.editingMode.syllable.buildPhonemeMenu",
  );
  const buildCustomColorsMenuTranslation: BuildCustomColorMenuTranslationProps = useI18nObjectHook(
    "project.existing.editingMode.syllable.buildCustomColorsMenu",
  );
  useEffect(() => {
    const noop = (e: Event) => {
      e.preventDefault();
      return false;
    };
    const contentEditableRefCurrent = contentEditableRef.current;
    contentEditableRefCurrent?.addEventListener("cut", noop, false);
    contentEditableRefCurrent?.addEventListener("paste", noop, false);
    contentEditableRefCurrent?.addEventListener("keydown", noop, false);
    contentEditableRefCurrent?.addEventListener("dragenter", noop, false);
    contentEditableRefCurrent?.addEventListener("dragleave", noop, false);
    contentEditableRefCurrent?.addEventListener("dragover", noop, false);
    contentEditableRefCurrent?.addEventListener("drop", noop, false);
    return () => {
      contentEditableRefCurrent?.removeEventListener("cut", noop);
      contentEditableRefCurrent?.removeEventListener("paste", noop);
      contentEditableRefCurrent?.removeEventListener("keydown", noop);
      contentEditableRefCurrent?.removeEventListener("dragenter", noop);
      contentEditableRefCurrent?.removeEventListener("dragleave", noop);
      contentEditableRefCurrent?.removeEventListener("dragover", noop);
      contentEditableRefCurrent?.removeEventListener("drop", noop);
    };
  }, [contentEditableRef]);

  const renderDropdownContent = useCallback(() => {
    if (mode === "syllables") {
      return buildSyllableMenu(
        selectedTextPosition,
        removeSyllable,
        merge,
        mute,
        unmute,
        buildSyllableMenuTranslation,
      );
    }
    if (mode === "phonemes") {
      return buildPhonemeMenu(
        selectedTextPosition,
        removePhoneme,
        assignPhoneme,
        mute,
        unmute,
        buildPhonemeMenuTranslation,
      );
    }
    return buildCustomColorMenu(
      selectedTextPosition,
      removeCustomColor,
      assignCustomColor,
      customColors,
      buildCustomColorsMenuTranslation,
    );
  }, [
    mode,
    selectedTextPosition,
    removeCustomColor,
    assignCustomColor,
    customColors,
    buildCustomColorsMenuTranslation,
    removeSyllable,
    merge,
    mute,
    unmute,
    buildSyllableMenuTranslation,
    removePhoneme,
    assignPhoneme,
    buildPhonemeMenuTranslation,
  ]);

  const { onMouseUp, onMouseDown } = useMemo(() => {
    return {
      onMouseUp: saveSelection,
      onMouseDown: (e: React.MouseEvent) => e.button === 0 && reset(),
    };
  }, [reset, saveSelection]);

  return (
    <Dropdown trigger={["contextMenu"]} dropdownRender={renderDropdownContent}>
      <div
        className={style.root.concat(" ", style[mode])}
        ref={contentEditableRef}
        contentEditable="true"
        suppressContentEditableWarning
        onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}>
        {mode === "custom" && (
          <style>{`
        .${style.root} .rhese {
          padding: 0.12em 0;
        }
        ${customColors
          .map(
            (customColor) => `
            .${style.root} .rhese .customColoration.color${customColor.key} {
            color: ${customColor.color};
          }
          `,
          )
          .join("\n")}
      `}</style>
        )}
        <RenderBook projectId={projectId}>{pageMetadata}</RenderBook>
      </div>
    </Dropdown>
  );
}
