import React, { useCallback, useEffect, useMemo, useState } from "react";
import DefinitionItem from "./DefinitionItem";
import * as style from "../style.less";
import AudioDropInput from "./AudioDropInput";
import useUploadFile from "./useUploadFile";
import useUploadRecordedFile from "./useUploadRecordedFile";
import {
  SubscribeToExtendedPage_extendedPage,
  SubscribeToExtendedPage_extendedPage_definition,
} from "../../../../../editor/Project/api/__generated__/SubscribeToExtendedPage";
import { useUpdateDefinition } from "../../../../../editor/Project/api/api";
import { getDefinitionText } from "../listening/DefinitionsListening";
import { AudioReviewStatus } from "../../../../../../../__generated__/globalTypes";

type Props = {
  definitions: SubscribeToExtendedPage_extendedPage_definition[];
  projectId: string;
  pageMetadata: SubscribeToExtendedPage_extendedPage;
};

const defaultAsyncState = { loading: true, data: null, error: null };

const useAsync = <T,>(promise: Promise<T>) => {
  const [state, setState] = useState<{
    loading: boolean;
    data: T | null;
    error: Error | null;
  }>(defaultAsyncState);
  useEffect(() => {
    setState(defaultAsyncState);
    promise
      .then((data) => setState((state) => ({ ...state, data, loading: false })))
      .catch((error) => setState((state) => ({ ...state, error, loading: false })));
  }, [promise]);

  return state;
};

const getMicrophone = async () => {
  const stream = await navigator.mediaDevices.getUserMedia({
    audio: true,
  });
  const mediaRecorder = new MediaRecorder(stream);

  return {
    start: () => {
      mediaRecorder.start();
    },
    stop: () => {
      return new Promise<Blob>((resolve, reject) => {
        mediaRecorder.ondataavailable = (e) => {
          const blob = new Blob([e.data], { type: "audio/ogg; codecs=opus" });
          resolve(blob);
        };
        mediaRecorder.onerror = (e) => {
          reject(e);
        };
        mediaRecorder.stop();
      });
    },
  };
};

const DefinitionsRecording = ({ definitions, projectId, pageMetadata }: Props) => {
  const {
    error,
    loading: microphoneLoading,
    data: microphone,
  } = useAsync(useMemo(getMicrophone, []));
  const [applyUpdateDefinition] = useUpdateDefinition();

  const handleSetDefinitionAudioFile = useCallback(
    async (definitionId: string, filename: string) => {
      const currentDefinition = definitions.find((d) => d.data.id === definitionId);
      if (!currentDefinition) {
        return;
      }

      const currentStatus = currentDefinition.data.audioReviewStatus;

      applyUpdateDefinition({
        variables: {
          projectId,
          definitionId,
          audioFile: filename,
          audioReviewStatus: currentStatus !== null ? AudioReviewStatus.RENARRATED : undefined,
          audioReviewComment: currentDefinition.data.audioReviewComment ?? "",
          description: currentDefinition.data.description ?? "",
          kind: currentDefinition.data.kind,
        },
      });
    },
    [applyUpdateDefinition, definitions, projectId],
  );

  const { uploadRecordedFile, isUploading: isRecordingIsUploading } = useUploadRecordedFile({
    setDefinitionAudioFile: handleSetDefinitionAudioFile,
    microphone,
  });
  const { uploadFile, isUploading } = useUploadFile({
    setDefinitionAudioFile: handleSetDefinitionAudioFile,
  });

  if (error) {
    return <p>Error - Could not get microphone</p>;
  }

  if (microphoneLoading || !microphone) {
    return <p>Please share your mike</p>;
  }

  return (
    <div className={style.definitions}>
      {definitions.map((definition) => {
        const textDefinition = getDefinitionText(definition, pageMetadata);

        return (
          <div key={definition.data.id} className={style.definitionWrapper}>
            <div className={style.definition}>
              <DefinitionItem
                key={definition.data.id}
                filename={definition.data.audioFile}
                definitionId={definition.data.id}
                onPressStart={microphone.start}
                onPressEnd={uploadRecordedFile}
                loading={isRecordingIsUploading || isUploading}
                audioReviewStatus={definition.data.audioReviewStatus}
                audioReviewComment={definition.data.audioReviewComment}>
                {`${textDefinition}: ${definition.data.description}`}
              </DefinitionItem>
            </div>
            <AudioDropInput
              definitionId={definition.data.id}
              sendFile={uploadFile}
              filename={definition.data.audioFile}
              loading={isRecordingIsUploading || isUploading}
              style={style}
            />
          </div>
        );
      })}
    </div>
  );
};

export default DefinitionsRecording;
