import React, { useCallback, useState } from "react";
import { BookSvg } from "../../../../../assets/svg/BookSvg";
import { useI18n } from "../../../../i18n";
import BackArrow from "../../Assets/BackArrow";
import { BookPanel } from "../panels/bookPanel/BookPanel";

import * as style from "./style.less";
import classNames from "classnames";
import { Spinner } from "../../../../components/Spinner";
import { SubToProject_project } from "../../../editor/Project/api/__generated__/SubToProject";
import { SubToPages_pages } from "../../../editor/Project/api/SubToPages";
import { useNavigate } from "react-router";

type HeaderBookProps = {
  bookInfoFromUrl: {
    bookId: string;
    currentPage: string;
  };
  book?: SubToProject_project;
  loading: boolean;
  page: SubToPages_pages | null;
  allPages: SubToPages_pages[];
};

const HeaderBook = ({ bookInfoFromUrl, book, loading, page, allPages }: HeaderBookProps) => {
  const [t] = useI18n();
  const [isBookPanelOpen, setIsBookPanelOpen] = useState(false);
  const navigate = useNavigate();
  const loaded = !!book && !loading;

  const handelGoBack = useCallback(() => navigate("/audio/library"), []);

  const handelOnClose = useCallback(() => setIsBookPanelOpen(false), []);

  return (
    <>
      <div className={style.headerBookContainer}>
        <div className={style.headerTitleContainer}>
          {loaded && <BackArrow onClick={handelGoBack} />}
          <div className={style.bookInfo}>
            {loaded ? (
              <>
                <div className={style.headerTitle}>{book.name}</div>
                <div className={style.headerAuthor}>{book.author}</div>
              </>
            ) : (
              <div style={{ position: "relative", marginTop: "2em" }}>
                {/** Only the spinner is shown. The texts (hidden) are only here to preserve the natural height of the header while loading, to avoid a jump after loading */}
                <div className={classNames(style.headerTitle, style.hidden)}>Loading</div>
                <div className={classNames(style.headerAuthor, style.hidden)}>Loading</div>
                <Spinner small className={style.headerSpinner} />
              </div>
            )}
          </div>
          {loaded && (
            <BookSvg
              onClick={() => {
                setIsBookPanelOpen(true);
              }}
            />
          )}
        </div>
        <div className={style.paginationBar}>
          <div
            className={classNames(style.pagination, {
              [style.halfHidden]: !loaded,
            })}>
            <div>{`${t("page")} ${page?.pageNumber || "?"}`}</div>
          </div>
          <div
            className={classNames({
              [style.halfHidden]: !loaded || !book.totalPageNumber,
            })}>
            {`${t("on")} ${page?.lastPageNumber || "?"}`}
          </div>
        </div>
      </div>
      <BookPanel
        isPanelOpen={isBookPanelOpen}
        bookId={bookInfoFromUrl.bookId}
        onClose={handelOnClose}
        bookRhesesAudioInfo={book?.rhesesAudioInfo}
        bookDefinitionsAudioInfo={book?.definitionsAudioInfo}
        allPages={allPages}
        loading={loading}
      />
    </>
  );
};

export default HeaderBook;
