import React from "react";
import { MenuProps, TabsProps } from "antd";
import { ThemeConfig } from "antd/lib/config-provider/context";
import { colors } from "./colors";

/**
 * Note: we use the theme below to customize what we can.
 * The rest is in "modules/app/editor/styles/antd.override.less"
 */

export const antDesignTheme: ThemeConfig = {
  hashed: false, // FIX anoying "css-dev-only" https://github.com/ant-design/ant-design/discussions/38753
  token: {
    fontFamily: "Roboto",
    colorPrimary: colors.orange,
    // @ts-expect-error
    fontSize: "1em",
    colorText: colors.darkestGrey,
    colorTextLabel: colors.darkGrey,
  },
  components: {
    Layout: {
      headerBg: colors.lightGrey,
    },
    Breadcrumb: {},
    Drawer: {
      fontWeightStrong: 500,
    },
    Tabs: {
      fontSize: 12,
    },
    Dropdown: {
      fontSize: 12,
      paddingContentVertical: 0,
      borderRadius: 2,
    },
    Menu: {
      colorItemBgActive: colors.greyfade10,
      borderRadius: 2,
    },
    Switch: {},
    Form: {
      fontSize: 12,
    },
    Button: {
      fontSize: 12,
      borderRadius: 2,
    },
    Select: {},
    Radio: {
      fontSize: 12,
    },
    Input: {},
    Modal: {
      // @ts-expect-error
      padding: "3.33em",
      fontSize: 12,
    },
    Tooltip: {
      fontSize: 12,
      colorBgBase: colors.grey,
    },
    Slider: {
      fontSize: 12,
    },
    Checkbox: {
      fontSize: 12,
    },
  },
};

/**
 * Menu items
 */

export type MenuItem = Required<MenuProps>["items"][number];

export const getMenuItem = ({
  key,
  icon,
  children,
  label,
  onClick,
  className,
  danger,
  disabled,
  onMouseEnter,
  onMouseLeave,
}: {
  key: React.Key | null;
  label?: React.ReactNode;
  icon?: React.ReactNode;
  children?: MenuItem[];
  onClick?: Function;
  className?: string;
  danger?: boolean;
  disabled?: boolean;
  onMouseEnter?: Function;
  onMouseLeave?: Function;
}): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
    onClick,
    className,
    danger,
    disabled,
    onMouseEnter,
    onMouseLeave,
  } as MenuItem;
};

/**
 * Tab items
 */

export type TabItems = TabsProps["items"];

export const getTabItem = ({
  key,
  label,
  children,
  className,
  disabled,
}: {
  key: string;
  label?: React.ReactNode;
  icon?: React.ReactNode;
  children?: string | React.ReactNode;
  onClick?: Function;
  className?: string;
  danger?: boolean;
  disabled?: boolean;
  onMouseEnter?: Function;
  onMouseLeave?: Function;
}) => {
  return {
    key,
    children,
    label,
    className,
    disabled,
  };
};
