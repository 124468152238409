import { ApolloClient, InMemoryCache, from } from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { onError } from "@apollo/client/link/error";
import { notification } from "antd";
import { GraphQLError } from "graphql";
import { translate } from "../i18n";
import { SupabaseClient } from "@supabase/supabase-js";

export enum HandledError {
  CANNOT_UPDATE_RHESE_CONTENT = "CANNOT_UPDATE_RHESE_CONTENT",
  CANNOT_FIND_PAGE = "CANNOT_FIND_PAGE",
}

const displayServerError = () =>
  translate.then((t) =>
    notification.error({
      message: t("serverHasSentError").toString(),
      description: t("errorHasBeenSentToTechStaff").toString(),
      placement: "topRight",
    }),
  );

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError) {
    displayServerError();
  } else if (graphQLErrors) {
    graphQLErrors = graphQLErrors.filter(
      (e) => e.extensions === undefined || e.extensions?.code !== "NOT_FOUND",
    );

    const { unhandledErrors, handledErrors } = graphQLErrors.reduce(
      (
        acc: {
          unhandledErrors: GraphQLError[];
          handledErrors: GraphQLError[];
        },
        e,
      ) => {
        if (
          typeof e.extensions?.code === "string" &&
          (Object.values(HandledError) as string[]).includes(e.extensions.code)
        ) {
          return {
            ...acc,
            handledErrors: acc.handledErrors.concat(e as GraphQLError),
          };
        }
        return {
          ...acc,
          unhandledErrors: acc.unhandledErrors.concat(e as GraphQLError),
        };
      },
      {
        unhandledErrors: [],
        handledErrors: [],
      },
    );

    handledErrors.forEach((e) => {
      if (e.extensions?.code === HandledError.CANNOT_UPDATE_RHESE_CONTENT) {
        translate.then((t) => {
          notification.warning({
            message: t("GQLError.cannotUpdateRheseContent.message").toString(),
            description: t("GQLError.cannotUpdateRheseContent.description").toString(),
            placement: "topRight",
          });
        });
      }
    });

    if (unhandledErrors.length === 0) {
      return;
    }

    displayServerError();
  }
});

export default (supabaseClient: SupabaseClient) => {
  const getToken = async () => {
    const accessToken = (await supabaseClient.auth.getSession()).data.session?.access_token;
    return `Bearer ${accessToken}`;
  };

  if (!process.env.GQL_API_ENDPOINT) {
    throw new Error("GQL_API_ENDPOINT MUST BE SET");
  }

  const uri: string = process.env.GQL_API_ENDPOINT.replace("https", "wss")
    .replace("http", "ws")
    .replace("/graphql", "/subscriptions");
  const wsLink = new WebSocketLink({
    uri,
    options: {
      reconnect: true,
      timeout: 30000,
      connectionParams: async () => ({
        authorization: await getToken(),
      }),
    },
  });

  return new ApolloClient({
    queryDeduplication: false,
    link: from([errorLink, wsLink]),
    cache: new InMemoryCache({
      addTypename: true,
      typePolicies: {
        SqlxRhese: {
          fields: {
            letters: {
              merge(_, incoming: any[]) {
                return [...incoming];
              },
            },
          },
        },
      },
    }),
  });
};
