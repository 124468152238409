import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { motion } from "framer-motion";
import { Button } from "antd";

import * as style from "./style.less";
import { useRemoveProjectFromCache, useSubToProject } from "../../api/api";
import { Spinner } from "../../";
import ProjectTabs from "./ProjectTabs";
import BadgeStatus from "../BadgeStatus/BadgeStatus";
import useProjectProgress from "../../hooks/useProjectProgress";
import { useIsAdmin } from "../../../../../identity";
import { useI18n } from "../../../../../i18n";
import { useNavigate } from "react-router";

const panelVariants = {
  visible: {
    opacity: 1,
    y: "0%",
  },
  hidden: {
    opacity: 0,
    y: "100%",
  },
};

const bgVariants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

type PanelProps = {
  projectId: string;
  isVisible: boolean;
  close: () => void;
};

export default function ProjectPanel({ isVisible, projectId, close }: PanelProps) {
  const navigate = useNavigate();
  const { loading, data } = useSubToProject(
    { id: projectId, withInfo: true, withAudioInfo: true },
    { skip: !isVisible },
  );
  const project = useMemo(() => data?.project && data?.project[0], [data?.project]);
  const projectRef = useRef(project);
  useEffect(() => {
    if (project) projectRef.current = project;
  }, [project]);
  const [applyRemoveProjectFromCache] = useRemoveProjectFromCache();
  const isAdmin = useIsAdmin();
  const [t] = useI18n();

  const lastLoadedProject = useMemo(() => project || projectRef.current, [project]);

  const projectProgressHook = useProjectProgress(lastLoadedProject);

  const { bookStatusLabel } = projectProgressHook;

  const shouldShowSpinner = useMemo(() => {
    if (projectRef.current) return false;
    if (!project) return true;
    if (loading) return true;
    return false;
  }, [loading, project]);
  const reload = useCallback(async () => {
    await applyRemoveProjectFromCache({ variables: { projectId } });
    navigate(0);
  }, [applyRemoveProjectFromCache, projectId]);

  return (
    <motion.div
      className={style.panelWrapper}
      initial="hidden"
      animate={isVisible ? "visible" : "hidden"}
      style={{ pointerEvents: isVisible ? "initial" : "none" }}
      transition={{ bounce: 0, delay: 0.2 }}>
      <motion.div
        className={style.transparentBackground}
        onClick={close}
        variants={bgVariants}
        transition={{ bounce: 0, delay: 0 }}
      />
      <motion.div
        transition={{ bounce: 0, delay: 0, duration: 0.2 }}
        variants={panelVariants}
        className={style.projectPanel}>
        {shouldShowSpinner && <Spinner style={{ width: "100%" }} />}
        {!shouldShowSpinner && lastLoadedProject && (
          <>
            <div className={style.cover}>
              <h3 className={style.name}>{lastLoadedProject.name}</h3>
              <div className={style.author}>{lastLoadedProject.author}</div>
              <BadgeStatus className={style.badgeStatus} bookStatusLabel={bookStatusLabel} />
              {isAdmin && (
                <Button shape={"round"} onClick={reload} className={style.reloadButton}>
                  {t("reload.project")}
                </Button>
              )}
            </div>
            {isVisible && (
              <ProjectTabs project={lastLoadedProject} projectProgressHook={projectProgressHook} />
            )}
          </>
        )}
      </motion.div>
    </motion.div>
  );
}
