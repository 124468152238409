import React, { ReactElement, useMemo, useRef } from "react";

import HeaderBook from "../../Components/header/HeaderBook";
import useProofListeningNav from "./ProofListening/useProofListeningNav";
import { Spinner } from "../../../../components/Spinner";
import { FrogRoles, useIsAdmin, useSelectedRole } from "../../../../identity";
import { useI18n } from "../../../../i18n";
import { Result } from "antd";
import * as style from "./style.less";
import { ProjectProvider, useProject } from "../../../editor/Project/components/Page";
import ReSyncOverlay from "../../Components/ReSyncOverlay/ReSyncOverlay";
import NarratorContent from "./NarratorContent/NarratorContent";
import {
  PageContentLoader,
  PageContentLoaderProps,
} from "../../../editor/Project/components/EditingRouter/components/PageContentLoader";
import ProofListenerContent from "./ProofListenerContent/ProofListenerContent";
import { useLocation, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

const Wrapper = ({
  content,
  scrollRef,
}: PageContentLoaderProps & {
  content: ReactElement;
  scrollRef: React.RefObject<HTMLDivElement>;
}) => {
  return (
    <div className={style.pageContent} ref={scrollRef}>
      <div>{content}</div>
    </div>
  );
};

const audioUrlPattern =
  /^\/audio\/library\/edit\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\/(\d+)$/;

export const NarrationBookPage = () => {
  const { bookId, page: currentPage } = useParams<{ bookId: string; page: string }>();

  const location = useLocation();
  const { state: selectedRole } = useSelectedRole();
  const isNarrator = selectedRole === FrogRoles.Narrator;
  const isProofListener = selectedRole === FrogRoles.ProofListener;
  const isAdmin = useIsAdmin();
  const [t] = useI18n();
  if (!bookId || !currentPage) {
    throw new Error("bookId and page parameters are mandatory");
  }
  const bookInfoFromUrl = { bookId, currentPage };

  const { project, pages, bookLoading, pagesLoading } = useProject({
    projectId: bookId,
    withPages: true,
    withInfo: true,
    withAudioInfo: location.pathname.match(audioUrlPattern) !== null,
  });

  const currentPageId = useMemo(
    () => pages.find((page) => page.pageNumber === Number(currentPage))?.id,
    [pages, currentPage],
  );

  const page = useMemo(
    () => pages.find((page) => page.pageNumber === Number(currentPage)),
    [pages, currentPage],
  );

  const { goBack } = useProofListeningNav(currentPageId || undefined, bookInfoFromUrl, pages);

  const scrollRef = useRef(null); // Needed for the pagination's load-more

  const isLoading = bookLoading || pagesLoading;
  const hasData = !(currentPageId === null) && project && pages;
  const noResult = pages.length === 0;
  const isInReSync = project?.isDoingAudioResync;

  if (isLoading && !hasData) {
    return <Spinner grow />;
  }

  if (noResult) {
    return <Result />;
  }

  const title = <h1>{t("rheses")}</h1>;

  let content: ReactElement | null = null;

  if (hasData && isNarrator) {
    content = (
      <NarratorContent
        currentPageId={currentPageId}
        bookId={bookId}
        bookInfoFromUrl={bookInfoFromUrl}
        scrollRef={scrollRef}
        pages={pages}
        selectedRole={selectedRole}
        title={title}
      />
    );
  } else if (hasData && (isProofListener || isAdmin)) {
    content = (
      <ProofListenerContent
        currentPageId={currentPageId}
        bookId={bookId}
        bookInfoFromUrl={bookInfoFromUrl}
        scrollRef={scrollRef}
        pages={pages}
        selectedRole={selectedRole}
        title={title}
        goBack={goBack}
      />
    );
  }

  return (
    <>
      <HeaderBook
        bookInfoFromUrl={bookInfoFromUrl}
        book={project}
        loading={!hasData}
        page={page || null}
        allPages={pages}
      />
      {hasData && page ? (
        <ProjectProvider value={{ project, pages }}>
          {isInReSync ? (
            <ReSyncOverlay />
          ) : (
            <>
              <PageContentLoader
                pageId={page?.id}
                projectId={project?.id}
                page={page}
                props={{ scrollRef, content: content || <></> }}
                component={Wrapper}
              />
            </>
          )}
        </ProjectProvider>
      ) : (
        <Spinner grow />
      )}
    </>
  );
};

export const MemoizedBook = React.memo(NarrationBookPage);
