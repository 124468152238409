import React, { useCallback, useMemo, useState } from "react";
import { Button, Input, Row } from "antd";
import { useI18n } from "../../../../../../../i18n";
import useAudioBuffer, { getAudioSource } from "./useAudioBuffer";
import { SynchroModalContentProps } from "./SynchroModal";
import SynchroSlider from "./SynchroSlider";
import ExtremityExtender from "./ExtremityExtender/ExtremityExtender";
import * as style from "./SynchroModal.style.less";
import { useUpdateAudioRhese } from "../../../../../../editor/Project/api/api";
import { preciseFloat } from "../../../../../Utils/common";
import PlayIcon from "../../assets/PlayIcon";

export const ADDITIONAL_TIME_STEP = 1; // in seconds

// Shortcut to round numbers
const r = (n: undefined | null | number) => preciseFloat(n || 0, 2);

const findCloserStep = (type: "start" | "end", value?: number) => {
  const val = value ? r(value) : undefined;

  if (val === 0 || val === undefined) {
    return 0;
  }

  if (type === "start" && val > 0) {
    return 0;
  }
  if (type === "end" && val < 0) {
    return 0;
  }

  let currentStep = ADDITIONAL_TIME_STEP;

  while (currentStep % val >= 1) {
    currentStep += ADDITIONAL_TIME_STEP;
  }

  return currentStep;
};

function getOffsets(
  info: SynchroModalContentProps["info"],
  defaultStartOffset: number | null,
  defaultEndOffset: number | null,
): {
  start: number | null;
  end: number | null;
} {
  const offsets = {
    start: defaultStartOffset,
    end: defaultEndOffset,
  };
  if (typeof info?.humanStart === "number" && typeof info?.start === "number") {
    offsets.start = info.humanStart - info.start;
  }
  if (typeof info?.humanEnd === "number" && typeof info?.end === "number") {
    offsets.end = info.humanEnd - info.end;
  }
  return offsets;
}

export default function SynchroModalContent({
  recording,
  projectId,
  info,
  onDone,
  defaultStartOffset,
  defaultEndOffset,
}: SynchroModalContentProps & { onDone: () => void }) {
  const [t] = useI18n();
  const offsets = useMemo(
    () => getOffsets(info, defaultStartOffset, defaultEndOffset),
    [info, defaultStartOffset, defaultEndOffset],
  );
  const [startOffset, setStartOffset] = useState(offsets.start || undefined);
  const [endOffset, setEndOffset] = useState(offsets.end || undefined);
  const audioContext = useMemo(() => new AudioContext(), []);
  const buffer = useAudioBuffer(audioContext, recording);
  const audioSource = getAudioSource(audioContext, buffer);
  const [applyUpdateAudioRhese] = useUpdateAudioRhese();

  /**
   * Shorthands
   */

  const { minOffsetStart, maxOffsetStart, minOffsetEnd, maxOffsetEnd } = useMemo(() => {
    const { buffer } = audioSource;

    if (!buffer || !info) {
      return {
        minOffsetStart: 0,
        maxOffsetStart: 0,
        minOffsetEnd: 0,
        maxOffsetEnd: 0,
      };
    }

    return {
      minOffsetStart: r(-(info.start || 0)),
      maxOffsetStart: r(buffer.duration - (info.start || 0)),
      minOffsetEnd: r(-(info.end || 0)),
      maxOffsetEnd: r(buffer.duration - (info.end || 0)),
    };
  }, [audioSource, info]);

  const playInfoStart = r((info?.start || 0) + (startOffset || 0));
  const playInfoEnd = r((info?.end || 0) + (endOffset || 0));
  const playInfoDuration = r(playInfoEnd - playInfoStart);
  const isDurationInvalid = playInfoDuration < 0;
  const fullAudioDuration = r(buffer?.duration);

  /**
   * Handlers
   */

  const play = useCallback(
    (offset: number, duration: number) => {
      if (duration <= 0) return;
      const audioSource = getAudioSource(audioContext, buffer);
      audioSource.start(0, offset, duration);
    },
    [audioContext, buffer],
  );

  const validate = useCallback(() => {
    applyUpdateAudioRhese({
      variables: {
        projectId,
        audioRheseId: info.id,
        humanStart: playInfoStart,
        humanEnd: playInfoEnd,
      },
    });
    onDone();
  }, [applyUpdateAudioRhese, projectId, info.id, playInfoStart, playInfoEnd, onDone]);

  const onReset = useCallback(() => {
    applyUpdateAudioRhese({
      variables: {
        projectId,
        audioRheseId: info.id,
        humanStart: null,
        humanEnd: null,
      },
    });
    onDone();
  }, [applyUpdateAudioRhese, info.id, onDone, projectId]);

  /**
   * Slider
   */

  const sliderDefaultValues = useMemo(() => {
    let sliderStart = info?.start || 0;
    let sliderEnd = info?.end || 0;
    if (typeof info?.humanStart === "number" && info.humanStart !== -1) {
      sliderStart = info.humanStart;
    }
    if (typeof info?.humanEnd === "number" && info.humanEnd !== -1) {
      sliderEnd = info.humanEnd;
    }
    return {
      start: r(sliderStart),
      end: r(sliderEnd),
    };
  }, [info]);

  // Additional time before.after start/end extremities
  const [additionalTimeBefore, setAdditionalTimeBefore] = useState(
    findCloserStep("start", startOffset),
  );
  const [additionalTimeAfter, setAdditionalTimeAfter] = useState(findCloserStep("end", endOffset));

  /**
   * Inputs
   */

  const onInputChange = useCallback(
    (type: "start" | "end") => (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = Number(e.target.value);

      if (type === "start") {
        if (value < -additionalTimeBefore) {
          setStartOffset(-additionalTimeBefore);
          return;
        }

        if (value < minOffsetStart) {
          value = minOffsetStart;
        }
        if (value > maxOffsetStart) {
          value = maxOffsetStart;
        }
        const val = r(value);
        setLastInputStartOffset(val);
        setStartOffset(val);
      } else {
        if (value > additionalTimeAfter) {
          setEndOffset(additionalTimeAfter);
          return;
        }

        if (value < minOffsetEnd) {
          value = minOffsetEnd;
        }
        if (value > maxOffsetEnd) {
          value = maxOffsetEnd;
        }
        const val = r(value);
        setLastInputEndOffset(-val);
        setEndOffset(val);
      }
    },
    [
      maxOffsetEnd,
      maxOffsetStart,
      minOffsetEnd,
      minOffsetStart,
      additionalTimeBefore,
      additionalTimeAfter,
    ],
  );

  // Needed to sync the slider after input changed manually
  const [lastInputStartOffset, setLastInputStartOffset] = useState(startOffset);
  const [lastInputEndOffset, setLastInputEndOffset] = useState(startOffset);

  return (
    <div className={style.content}>
      <div className={style.sliderContainer}>
        <SynchroSlider
          defaultStart={sliderDefaultValues.start}
          defaultEnd={sliderDefaultValues.end}
          originalStart={r(info?.start)}
          originalEnd={r(info?.end)}
          additionalTimeBefore={additionalTimeBefore}
          additionalTimeAfter={additionalTimeAfter}
          setStartOffset={setStartOffset}
          setEndOffset={setEndOffset}
          lastInputStartOffset={lastInputStartOffset}
          lastInputEndOffset={lastInputEndOffset}
        />
        <ExtremityExtender
          playInfoEnd={playInfoEnd}
          playInfoDuration={playInfoDuration}
          fullAudioDuration={fullAudioDuration}
          minOffsetStart={minOffsetStart}
          hasMaxOffsetEnd={!!maxOffsetEnd}
          additionalTimeBefore={additionalTimeBefore}
          additionalTimeAfter={additionalTimeAfter}
          setAdditionalTimeBefore={setAdditionalTimeBefore}
          setAdditionalTimeAfter={setAdditionalTimeAfter}
          sliderDefaultValues={sliderDefaultValues}
        />
      </div>
      <Row className={style.inputRow}>
        <Input
          className={style.input}
          // "0" value will prevent from typing negative values
          // @ts-expect-error
          value={startOffset !== 0 && startOffset !== undefined ? r(startOffset) : null}
          onChange={onInputChange("start")}
          step={0.05}
          type="number"
          placeholder={t("startOffset")}
        />
        <div className={style.durationAndPlayBtn}>
          <p className={style.duration}>{`${playInfoDuration.toFixed(2)} s`}</p>
          <Button
            disabled={isDurationInvalid}
            onClick={() => play(playInfoStart, playInfoDuration)}
            className={style.listenButton}>
            {/* <PlayCircleOutlined /> */}
            <PlayIcon />
          </Button>
        </div>
        <Input
          className={style.input}
          // "0" value will prevent from typing negative values
          // @ts-expect-error
          value={endOffset !== 0 && endOffset !== undefined ? r(endOffset) : null}
          onChange={onInputChange("end")}
          step={0.05}
          type="number"
          placeholder={t("endOffset")}
        />
      </Row>
      {isDurationInvalid && <span>{t("offsetConfigIsInvalid")}</span>}
      <div className={style.actionBar}>
        <Button shape="round" onClick={onDone}>
          {t("cancel")}
        </Button>
        <div className={style.extender} />
        <Button shape="round" onClick={onReset} className={style.buttonReset}>
          {t("reset")}
        </Button>
        <Button onClick={validate} disabled={isDurationInvalid} type="primary" shape="round">
          {t("apply")}
        </Button>
      </div>
    </div>
  );
}
