import React, { RefObject, useCallback } from "react";
import { AudioReviewStatus } from "../../../../../../__generated__/globalTypes";
import { useAddAudioReview } from "../../../Subscriptions/subscriptions";
import { UseAudioModeDataArgs } from "./useAudioModeData";
import ProofListening from "./PageProofListening";
import AudioPlayerMachineProvider from "./AudioPlayer/audioPlayerMachine/AudioPlayerMachineProvider";
import { SubToPages_pages } from "../../../../editor/Project/api/SubToPages";
import { SubscribeToExtendedPage_extendedPage } from "../../../../editor/Project/api/__generated__/SubscribeToExtendedPage";

type Props = UseAudioModeDataArgs & {
  title: React.ReactNode;
  scrollRef: RefObject<HTMLElement>;
  key: string;
  allPages: SubToPages_pages[];
  pageMetadata: SubscribeToExtendedPage_extendedPage;
};

export default function PageProofListeningProvider({
  bookInfoFromUrl,
  currentPageId,
  title,
  scrollRef,
  allPages,
  pageMetadata,
}: Props) {
  /**
   * Handlers
   */
  const [addAudioReview] = useAddAudioReview();
  const maybeAddAudioReview = useCallback(
    (rheseId: string) => {
      const hasReview = pageMetadata.rhese.find((r) => r.data.id === rheseId)?.data.audioInfo
        ?.status;
      const skipAutomaticReview = !!hasReview;
      if (skipAutomaticReview) return;
      addAudioReview({
        variables: {
          projectId: bookInfoFromUrl.bookId,
          rheseId: rheseId,
          status: AudioReviewStatus.VALID,
        },
      });
    },
    [addAudioReview, bookInfoFromUrl.bookId, pageMetadata?.rhese],
  );

  /**
   * Loading state
   */
  return (
    <AudioPlayerMachineProvider>
      {!!pageMetadata && (
        <ProofListening
          title={title}
          scrollRef={scrollRef}
          bookInfoFromUrl={bookInfoFromUrl}
          currentPageId={currentPageId}
          maybeAddAudioReview={maybeAddAudioReview}
          allPages={allPages}
          pageMetadata={pageMetadata}
        />
      )}
    </AudioPlayerMachineProvider>
  );
}
