import React, { useMemo, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Layout, Tooltip, Tabs } from "antd";
import * as style from "./style.less";
import Preview from "./assets/Apercu.svg";
import Rhese from "./assets/Rhesage.svg";
import Formatting from "./assets/MiseEnPage.svg";
import Definitions from "./assets/Definitions.svg";
import Dialogues from "./assets/Dialogues.svg";
import Syllables from "./assets/Syllabation.svg";
import LogoFrog from "./assets/LogoFrog.svg";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "../../";
import { useI18n } from "../../../../../i18n";
import { EDITOR_TABS_MAP } from "../../../config";
import { useProject } from "../Page";
import ProjectPanel from "../ProjectPanel/ProjectPanel";
import { getTabItem, TabItems } from "../../../styles/antDesignTheme";

const { Header } = Layout;

export default function ActionsBar({
  projectId,
  toggleSearch,
}: {
  projectId: string;
  loading: boolean;
  toggleSearch: () => void;
  isSearchOpen: boolean;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const lastPart = (location.pathname.match(/[^/]*$/) || [""])[0];
  const { project, bookLoading: loading } = useProject({ projectId });

  const [t] = useI18n();
  const i18nKey = "project.actionBar.default.tabPaneTitle";

  const content = useMemo(() => {
    if (loading || !project) {
      return <Spinner style={{ gridColumn: "1/5" }} />;
    }

    const tabItems: TabItems = [
      getTabItem({
        key: "1",
        label: (
          <Tooltip
            getPopupContainer={() => ref.current || document.body}
            title={t(`${i18nKey}.preview`)}>
            <Preview />
          </Tooltip>
        ),
      }),
      getTabItem({
        key: "2",
        label: (
          <Tooltip
            getPopupContainer={() => ref.current || document.body}
            title={t(`${i18nKey}.rhese`)}>
            <Rhese />
          </Tooltip>
        ),
      }),
      getTabItem({
        key: "3",
        label: (
          <Tooltip
            getPopupContainer={() => ref.current || document.body}
            title={t(`${i18nKey}.formatting`)}>
            <Formatting />
          </Tooltip>
        ),
      }),
      getTabItem({
        key: "4",
        label: (
          <Tooltip
            getPopupContainer={() => ref.current || document.body}
            title={t(`${i18nKey}.definitions`)}>
            <Definitions />
          </Tooltip>
        ),
      }),
      getTabItem({
        key: "5",
        disabled: true,
        label: (
          <Tooltip
            getPopupContainer={() => ref.current || document.body}
            title={t(`${i18nKey}.dialogues`)}>
            <Dialogues style={{ opacity: 0.5 }} />
          </Tooltip>
        ),
      }),
      getTabItem({
        key: "6",
        label: (
          <Tooltip
            getPopupContainer={() => ref.current || document.body}
            title={t(`${i18nKey}.syllables`)}>
            <Syllables />
          </Tooltip>
        ),
      }),
    ];

    return (
      <>
        <nav className={style.nav}>
          <Link className={style.logo} to="/">
            <LogoFrog />
          </Link>
          {">"}
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsPanelOpen(!isPanelOpen);
            }}>
            {project.name}
          </button>
        </nav>
        <div ref={ref} style={{ position: "absolute", width: "100%" }}></div>
        <Tabs
          defaultActiveKey={EDITOR_TABS_MAP[lastPart] || "1"}
          tabPosition="bottom"
          id={style.tabs}
          onChange={(key) => {
            const push = (val: string) =>
              navigate({
                pathname: window.location.pathname.replace(/[^/]*$/, val),
                search: window.location.search,
              });
            switch (key) {
              case "1":
                return push("overview");
              case "2":
                return push("rhese");
              case "3":
                return push("format");
              case "4":
                return push("definition");
              case "5":
                return push("dialogue");
              case "6":
                return push("syllable");
              case "7":
                return push("narration");
            }
          }}
          centered
          items={tabItems}
        />
        <div className={style.search} onClick={toggleSearch}>
          <SearchOutlined style={{ fontSize: "2em" }} />
        </div>
      </>
    );
  }, [loading, project, lastPart, t, toggleSearch, isPanelOpen, history]);

  if (!loading && project === undefined) {
    return <Navigate to="/" />;
  }

  return (
    <div className={style.wrapper}>
      {project && (
        <ProjectPanel
          projectId={projectId}
          close={() => setIsPanelOpen(false)}
          isVisible={isPanelOpen}
        />
      )}
      <Header id={style.header}>{content}</Header>
    </div>
  );
}
