import { useActor } from "@xstate/react";
import { useCallback, useContext } from "react";
import { AudioPlayerMachineContext } from "./AudioPlayerMachineProvider";

export default function useAudioPlayerMachine() {
  const { audioPlayerService } = useContext(AudioPlayerMachineContext);

  const [state, send] = useActor(audioPlayerService);

  const isLoadingRessources = state.matches("LOADING ASSETS");
  const isWaiting = state.matches("READY.WAITING");
  const isPaused = state.matches("READY.PAUSED");
  const isPlaying = state.matches("READY.PLAYING");

  const togglePlay = useCallback(() => {
    if (isWaiting) {
      send({
        type: "Play",
      });
    }
    if (isPaused) {
      send({
        type: "Resume",
      });
    }
    if (isPlaying) {
      send({
        type: "Pause",
      });
    }
  }, [isPaused, isPlaying, isWaiting, send]);

  return {
    isInitializing: state.matches("INITIAL"),
    state,
    send,
    // shortcuts
    isLoadingRessources,
    isPaused: isWaiting || isPaused,
    isPlaying,
    isWaiting,
    togglePlay,
    currentRheseId: state.context.currentRheseId,
  };
}
