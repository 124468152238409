import { useCallback, useMemo, useState } from "react";
import { ExportStatus } from "../../../../../../../__generated__/globalTypes";
import {
  useDeleteExport,
  useGetExportDownloadLink,
  useSubToExports,
} from "../../../../Project/api/api";
import { sortByRecent, filteredByProjectId, retentionFilter, filterOnSearch } from "./filters";

export default function useProjectExports(projectId?: string, search = "") {
  const shouldFilterDoneIfOld = !projectId; // Filter only on globla export tab

  /**
   * Data
   */

  const { data: { exports } = { exports: [] } } = useSubToExports();
  const [getExportDownloadLink] = useGetExportDownloadLink();
  const [deleteExport, { loading: deleteLoading }] = useDeleteExport();
  const [deletingExportIds, setDeletingExportIds] = useState<string[]>([]);

  const filteredExports = useMemo(() => {
    const exp1 = sortByRecent(exports); // Sort, more recent first
    const exp2 = retentionFilter(exp1, shouldFilterDoneIfOld); // Filter less than 24h if DONE (only in global view)
    const exp3 = filteredByProjectId(exp2, projectId); // Filters only if not global view
    const exp4 = filterOnSearch(exp3, search);
    return exp4;
  }, [exports, projectId, shouldFilterDoneIfOld, search]);

  const processing = useMemo(() => {
    const count = exports.reduce((count, exp) => {
      const isProcessing =
        exp.exportStatus === ExportStatus.WAITING || exp.exportStatus === ExportStatus.IN_PROGRESS;
      return isProcessing ? count + 1 : count;
    }, 0);

    const processingExports = exports.filter(
      (exp) =>
        exp.exportStatus === ExportStatus.WAITING || exp.exportStatus === ExportStatus.IN_PROGRESS,
    );

    return { count, exports: processingExports };
  }, [exports]);

  /**
   * Handlers
   */

  const cancelExport = useCallback(
    async (projectId: string, exportId: string) => {
      try {
        setDeletingExportIds([exportId]);

        await deleteExport({
          variables: {
            exports: [
              {
                exportId,
                projectId,
              },
            ],
          },
        });
      } catch (e) {
        console.error(e);
      } finally {
        setDeletingExportIds([]);
      }
    },
    [deleteExport],
  );

  // Get download URL and download
  const downloadExport = useCallback(
    async (projectId: string, exportId: string) => {
      // Fetch the download URL
      const res = await getExportDownloadLink({ variables: { projectId, exportId } });
      const downloadURL = res.data?.exportDownloadUrl;
      if (downloadURL) {
        // Download the file
        const link = document.createElement("a");
        link.href = downloadURL;
        link.click();
      }
    },
    [getExportDownloadLink],
  );

  const deleteExports = useCallback(
    async (exportIds: string[]) => {
      try {
        const exportsMap = exportIds.map((exportId) => ({
          exportId,
          projectId: exports.find((exp) => exp.id === exportId)?.projectId,
        }));
        setDeletingExportIds(exportIds);
        await deleteExport({
          variables: {
            exports: exportsMap,
          },
        });
      } catch (e) {
        console.error(e);
      } finally {
        setDeletingExportIds([]);
      }
    },
    [deleteExport, exports],
  );

  return {
    // Data
    exports: filteredExports,
    processing,
    // Handlers
    cancelExport,
    downloadExport,
    deleteExports,
    // State
    deleteLoading,
    deletingExportIds,
  };
}
