import React, { useCallback, useState } from "react";
import { Modal, Form, Input, Row, Button, Radio } from "antd";
import * as style from "./style.less";
import {
  useAssignToNewParagraph,
  useAddImage,
  useCreatePageFromPosition,
  useUpdateRhese,
  useUpdatePage,
} from "../../../api/api";
import { useI18n } from "../../../../../../i18n";
import {
  ImagePosition,
  ImageKind,
  ExtendedParagraphKind,
} from "../../../../../../../__generated__/globalTypes";
import SideMenu from "./components/SideMenu";
import { normalizeStr } from "../../../../utils/common";
import FormattingComp, { SelectedTextPosition } from "./components/FormattingComp/FormattingComp";
import useHandleLettersKindAssignation from "./hooks/useHandleLettersKindAssignation";
import { SplitContent } from "../../EditingRouter/components/SplitContent";
import {
  SubscribeToExtendedPage_extendedPage,
  SubscribeToExtendedPage_extendedPage_rhese,
} from "../../../api/__generated__/SubscribeToExtendedPage";
import { SubToPages_pages } from "../../../api/SubToPages";
import { SubToProject_project_speakers } from "../../../api/__generated__/SubToProject";
import { useUpdateDisplayedPageNumber } from "../../Page";

const RadioButton = Radio.Button;

type PropTypes = {
  allPages: SubToPages_pages[];
  projectId: string;
  lockPageStructure: boolean;
  speakers: SubToProject_project_speakers[];
};

export const radioStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export type SelectedDialogueSpeaker = {
  speakerId: string;
  paragraphId: string;
};

// eslint-disable-next-line react/display-name
export const PaginatedFormatting = ({
  allPages,
  projectId,
  lockPageStructure,
  speakers,
}: PropTypes) => {
  const [applyCreatePage] = useCreatePageFromPosition();
  const [applyUpdatePage] = useUpdatePage();
  const [applyUpdateRhese] = useUpdateRhese();
  const [applyAssignToNewParagraph] = useAssignToNewParagraph();
  const [applyAddImage] = useAddImage();
  const [form] = Form.useForm();
  const [targetParagraph, setTargetParagraph] = useState<string | null>(null);
  const [selectedTextPosition, setSelectedTextPosition] = useState<SelectedTextPosition>({
    start: 0,
    size: 0,
  });
  const [pageMetadata, setPageMetadata] = useState<SubscribeToExtendedPage_extendedPage | null>(
    null,
  );

  const updateDisplayedPageNumber = useUpdateDisplayedPageNumber({ forceRedirect: true });

  const { handleLettersKindAssignation } = useHandleLettersKindAssignation(
    selectedTextPosition,
    projectId,
    pageMetadata,
  );

  const assignRhesesToParagraph = useCallback(
    (rheseIds: string[], paragraphKind: ExtendedParagraphKind, paragraphTagId?: string) => {
      applyAssignToNewParagraph({
        variables: {
          paragraphKind: paragraphKind,
          rheses: rheseIds,
          projectId,
          paragraphTagId,
        },
      });
    },
    [applyAssignToNewParagraph, projectId],
  );

  const [isDraggingImage, setIsDraggingImage] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [figcaption, setFigcaption] = useState("");
  const [imageKind, setImageKind] = useState(ImageKind.NORMAL);
  const [imagePosition, setImagePosition] = useState(ImagePosition.BEFORE);
  const [t] = useI18n();
  const i18nKey = "project.existing.editingMode.formatting.default";
  const clear = useCallback(() => {
    setName("");
    setDescription("");
    setFigcaption("");
    setImageKind(ImageKind.NORMAL);
    setImagePosition(ImagePosition.BEFORE);
    setTargetParagraph(null);
    form.resetFields();
  }, [form]);

  const showModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleOk = useCallback(() => {
    setIsModalVisible(false);
    if (targetParagraph !== null) {
      applyAddImage({
        variables: {
          projectId: projectId,
          paragraphId: targetParagraph,
          kind: imageKind,
          position: imagePosition,
          fileName: normalizeStr(name),
          description: description,
          figcaption: figcaption,
        },
      });
    }
    clear();
  }, [
    targetParagraph,
    clear,
    applyAddImage,
    projectId,
    imageKind,
    imagePosition,
    name,
    description,
    figcaption,
  ]);

  const handleCancel = () => {
    setIsModalVisible(false);
    clear();
  };

  const handleImageCreation = useCallback(() => {
    if (!targetParagraph) return;
    showModal();
  }, [showModal, targetParagraph]);

  const onTextSelectionChange = useCallback(
    (
      selectedPosition: SelectedTextPosition,
      pageMetadata: SubscribeToExtendedPage_extendedPage,
    ) => {
      setSelectedTextPosition(selectedPosition);
      setPageMetadata(pageMetadata);
    },
    [],
  );

  const handleMutePageAfter = useCallback(
    async (id: string, mutePages: number) => {
      const page = allPages.find((page) => page.id === id);
      if (!page) {
        throw new Error("Could not retrieve page in pages list");
      }
      await applyUpdatePage({
        variables: {
          projectId,
          pageId: id,
          mutePagesAfter: mutePages,
          partname: page.partName || "",
        },
      });
    },
    [allPages, applyUpdatePage, projectId],
  );

  const handleMergePages = useCallback(
    async (pageId: string) => {
      const currentPage = allPages.find((page) => page.id === pageId);
      if (!currentPage) {
        throw new Error("Could not retrieve current page in pages list");
      }
      const nextPage = allPages.find((page) => page.pageNumber === currentPage?.pageNumber + 1);
      if (!nextPage) {
        throw new Error("Could not retrieve next page in pages list");
      }

      updateDisplayedPageNumber(currentPage.pageNumber.toString());
      await applyCreatePage({
        variables: {
          projectId,
          stringVersion: currentPage.stringVersion,
          start: currentPage.start,
          size: nextPage.start + nextPage.size - currentPage.start,
          mutePagesAfter: nextPage.mutePagesAfter,
          partname: currentPage.partName,
        },
      });
    },
    [allPages, applyCreatePage, projectId, updateDisplayedPageNumber],
  );

  const handleSetLineBreakAfterRhese = useCallback(
    async (previousRhese: SubscribeToExtendedPage_extendedPage_rhese, lineBreak) => {
      applyUpdateRhese({
        variables: {
          projectId,
          rheseId: previousRhese.data.id,
          lineBreakAfter: lineBreak,
          ignoreTextForAudioSync: previousRhese.data.ignoreTextForAudioSync,
        },
      });
    },
    [applyUpdateRhese, projectId],
  );

  return (
    <>
      <Modal
        title={t(`${i18nKey}.modal.title`)}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}>
        <Form name="basic" onFinish={handleOk} layout="vertical" form={form}>
          <Form.Item
            style={{ padding: "0 7em" }}
            label={t(`${i18nKey}.form.imageNaming.label`)}
            name="name">
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </Form.Item>
          <Form.Item
            style={{ padding: "0 7em" }}
            label={t(`${i18nKey}.form.imageDescription.label`)}
            name="description">
            <Input value={description} onChange={(e) => setDescription(e.target.value)} />
          </Form.Item>
          <Form.Item
            style={{ padding: "0 7em" }}
            label={t(`${i18nKey}.form.figcaption.label`)}
            name="figcaption">
            <Input value={figcaption} onChange={(e) => setFigcaption(e.target.value)} />
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <Row justify="center">
              <Radio.Group
                value={imageKind}
                style={{
                  display: "flex",
                  fontSize: "1.25em",
                }}
                onChange={(e) =>
                  setImageKind(
                    e.target.value === ImageKind.NORMAL ? ImageKind.NORMAL : ImageKind.POPUP,
                  )
                }
                optionType="button">
                <RadioButton style={radioStyle} value={ImageKind.NORMAL}>
                  {t(`${i18nKey}.form.radioButton.normal`)}
                </RadioButton>
                <RadioButton style={radioStyle} value={ImageKind.POPUP}>
                  {t(`${i18nKey}.form.radioButton.popup`)}
                </RadioButton>
              </Radio.Group>
            </Row>
          </Form.Item>
          <Form.Item style={{ margin: 0, marginTop: "2em" }}>
            <Row justify="center">
              <Button disabled={name.length === 0} htmlType="submit" shape="round">
                {t(`${i18nKey}.form.validation`)}
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
      <SplitContent
        mergePages={handleMergePages}
        addMutePages={handleMutePageAfter}
        props={{
          onTextSelectionChange,
          setTargetParagraph,
          isDraggingImage,
          setPosition: setImagePosition,
          assignRhesesToParagraph,
          lockPageStructure,
          setLineBreakAfterRhese: handleSetLineBreakAfterRhese,
          projectId: projectId,
          speakers,
          allPages,
        }}
        component={FormattingComp}>
        {allPages}
      </SplitContent>
      <div className={style.side}>
        <SideMenu
          handleLettersKindAssignation={handleLettersKindAssignation}
          sideMenuClassName={style.sideMenu}
          setIsDraggingImage={setIsDraggingImage}
          isDraggingImage={isDraggingImage}
          handleImageCreation={handleImageCreation}
          targetParagraph={targetParagraph}
        />
      </div>
    </>
  );
};
